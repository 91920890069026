import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const getJRVByDistrit = (_ced, _cant, _codDistrital) => {
  return axiosInstance.get(
    `fiscalesGenerales/getJuntas/${_ced}/${_cant}/${_codDistrital}`,
  );
};

const saveFiscalGeneral = _info => {
  return axiosInstance.post('fiscalesGenerales/', _info);
};

const geFiscalGeneralByNumLinea = _linea => {
  return axiosInstance.get(
    `fiscalesGenerales/find/${_linea}`,
  );
};

const getFiscalesGenerales = _ced => {
  return axiosInstance.get(
    `fiscalesGenerales/allByGestor/${_ced}`,
  );
};

const getFiscalesGeneralesFiltered = (
  _ced,
  _codProvincia,
  _codCanton,
  _codDistrito,
  _nJrv,
) => {
  return axiosInstance.get(
    // eslint-disable-next-line max-len
    `fiscalesGenerales/allByGestorFiltered/${_ced}/${_codProvincia}/${_codCanton}/${_codDistrito}/${_nJrv}`,
  );
};

const validateFiscalGOnAdd = _ced => {
  return axiosInstance.get(
    `fiscalesGenerales/validateCedula/${_ced}`,
  );
};

const deleteMember = _numLinea => {
  return axiosInstance.get(
    `fiscalesGenerales/removeMember/${_numLinea}`,
  );
};

export default {
  getJuntaReceptora: getJRVByDistrit,
  getAllFiscalesGenerales: getFiscalesGenerales,
  getAllFiscalesGeneralesFiltered: getFiscalesGeneralesFiltered,
  getFiscalGeneralByLinea: geFiscalGeneralByNumLinea,
  submitFiscalGeneral: saveFiscalGeneral,
  validateFiscalGOnAdd,
  deleteMember,
};
