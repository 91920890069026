import { colors } from '../../Core/Theme/theme';

const styles = () => ({
  loginBox: {
    root: { backgroundColor: '#fff' },
    backgroundColor: '#fff',
    padding: '30px 20px 20px 20px',
    maxWidth: 600,
  },
  accountBox: {
    backgroundColor: '#fff',
    textAlign: 'center',
    padding: '10px 30px 30px 30px',
  },
  title: {
    marginTop: '10px',
    color: '#212529',
    textAlign: 'center',
  },
  subtitle: {
    marginTop: '0px',
    color: '#555555',
    textAlign: 'center',
    letterSpacing: '3px',
  },
  form: {
    marginTop: 35,
  },
  labelText: { color: '#545454' },
  linkText: {
    color: '#008945',
    fontSize: '.8em',
    margin: '0 5px 0 5px',
  },
  input: {
    display: 'block',
    width: '100%',
    fontSize: '.9em',
    padding: 20,
    borderRadius: 10,
    borderColor: colors.lightGray,
    borderWidth: 1,
    borderStyle: 'solid',
    margin: '5px 0 20px',
  },
  submitButton: {
    display: 'block',
    width: '100%',
    marginTop: 20,
    textTransform: 'none',
    fontSize: '.9em',
  },
  error: {
    margin: '5px 0px 25px 0px',
    color: `${colors.error}`,
  },
  formError: {
    margin: '5px 0px 25px 0px',
    color: `${colors.error}`,
  },
  inverseButton: {
    display: 'block',
    width: '100%',
    background: '#fff',
    fontSize: '.9em',
    color: `${colors.orange}`,
    marginTop: 20,
    textTransform: 'none',
    borderRadius: 10,
    borderColor: `${colors.orange}`,
    borderWidth: 2,
    borderStyle: 'solid',
    '&:hover': {
      backgroundColor: `${colors.white}`,
    },
  },
  spinner: {
    color: '#008945',
  },
  forgotPwdLink: {
    textAlign: 'right',
    fontWeight: 'bold',
    '& a': {
      color: '#008945',
      textDecoration: 'none',
      '&:hover': {
        color: '#84BF48',
      },
    },
  },
  backToLogin: {
    textAlign: 'center',
    fontWeight: 'bold',
    '& a': {
      color: '#008945',
      textDecoration: 'none',
      '&:hover': {
        color: '#84BF48',
      },
    },
  },
});

export default styles;
