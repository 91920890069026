import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const login = params => {
  const { nickname, password, ipAddress } = params;

  const profileData = {
    nickname,
    pwd: password,
    ip: ipAddress,
  };

  return axios.post('/auth/loginTen', profileData);
};

export const forgotPwd = params => {
  const { nickname } = params;

  const profileData = {
    nickname,
  };

  return axios.post('/auth/forgotPassword', profileData);
};

export const getDataLocation = () => {
  return axios.get(
    'https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572',
  );
};

export const addUser = params => {
  const { uid, email } = params;

  const profileData = {
    auth_id: uid,
    email,
  };

  return axios.post('/profiles', profileData);
};

export const getProfile = params => {
  const { token, uid } = params;

  const options = {
    headers: { 'Auth-Token': token },
  };

  const url = [`/profiles/${uid}`];

  return axios.get(url.join('&'), options);
};

export const updateProfile = params => {
  const { profile, token } = params;

  const options = {
    headers: { 'Auth-Token': token },
  };

  return axios.patch('/profiles', profile, options);
};
