/* eslint-disable radix */
/* eslint-disable camelcase */
import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import Lodash from 'lodash';
import { useHistory } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { Formik } from 'formik';
import * as Yup from 'yup';
import BalanceContext from '../../Core/Context/AppContext';
import style from './styles';

import Copyright from '../Copyright/Copyright';
import ListSearch from '../../Components/ListSearch/ListSearch';
import {
  getEstadoPapeleta,
  formatData,
} from '../../Core/Utils/Utils';
import { generateComprobantePresidenteSector } from '../../Core/Utils/Comprobante';
import { startPrevencion } from '../../Core/Utils/Prevencion';

import Consts from '../../Core/Consts/Consts';
import Storage from '../../Storage/Storage';

import {
  addNew,
  updatePapeleta,
  // getNumPapeletasNacionales,
  // getNumPapeletaStatus,
  // blockNumPapeleta,
} from '../../Core/Api/papeletaPresidenteSector';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const AddPresidenteSector = props => {
  const { classes } = props;
  const history = useHistory();

  const [user, setUser] = useState([]);
  const [statusPapeleta] = useState(3);
  const [dateField, setDateField] = useState('');
  const [consecutivo, setConsecutivo] = useState('');
  const [codPapeleta, setCodPapeleta] = useState(null);
  const [fecPapeleta, setFecPapeleta] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const tokenData = Storage.instance;
  const { decodedToken } = useJwt(tokenData.getToken());

  const [msjDialog, setMsjDialog] = useState('');
  const [open, setOpenDialog] = React.useState(false);
  const [finalDialog, setFinalDialog] = React.useState(
    false,
  );
  const handleClose = () => {
    setOpenDialog(false);
    setIsSaving(false);
  };

  const closeFinalDialog = () => {
    history.push('/presi-sectores');
  };

  // Date Options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // timeZone: 'UTC',
  };

  const { balance, setGlobalBalance } = useContext(
    BalanceContext,
  );

  useEffect(() => {
    if (decodedToken !== null) {
      setUser(decodedToken);
    }

    const today = new Date();
    setDateField(
      today.toLocaleDateString('es-ES', options),
    );
  }, [decodedToken, options]);

  // Opciones Seleccionadas
  const [numCandidatos] = useState(1);
  const [listPresiSector, setlistPresiSector] = useState(
    Consts.initlistPresiSector,
  );

  // Lista de: Provincias, Cantones, Distritos
  const [initialValues] = useState({
    presidente: '',
  });

  const validationSchema = Yup.object({});

  // Se valida que exista al menos 1 en la lista
  const verifyIfCanSave = lCandidates => {
    const totalRegistersOnList = Object.keys(
      lCandidates,
    ).map(c => {
      const total = Lodash.countBy(lCandidates[c], rec => {
        return rec.filled === true;
      });
      const result = Lodash.get(total, 'true', 0);
      return { count: result };
    });

    const canSave = Lodash.find(totalRegistersOnList, o => {
      return o.count > 0;
    });

    let status = true;
    if (!canSave) {
      status = false;
    }

    return status;
  };

  const saveForm = (values, { setSubmitting }) => {
    // Se valida que TODOS los campos esten completos al 100%
    const totalComite = Lodash.countBy(
      listPresiSector,
      rec => {
        return rec.filled === false;
      },
    );

    if (totalComite.true !== undefined) {
      setMsjDialog(
        'Debe de completarse TODOS los campos de la papeleta al 100%',
      );
      setOpenDialog(true);
      setSubmitting(false);
      return;
    }

    const tFrmsCandidatos = {
      comite: listPresiSector,
    };

    // Se valida que exista al menos 1 candidato en la papeleta
    const canSave = verifyIfCanSave(tFrmsCandidatos);
    if (canSave) {
      const presidenteEnca = {
        num_consecutivo: consecutivo,
        num_cedula: tFrmsCandidatos.comite[0].cedula,
        cedula_resp: user.num_cedula,
        usuario: user.des_login,
        cod_estado: 2,
      };
      if (consecutivo === '') {
        // console.log('START SAVE');
        addNew({ presidenteEnca }).then(rNew => {
          const nCodPapeleta = Lodash.get(
            rNew,
            ['data', 'codPapeleta'],
            '',
          );
          const nConsecutivo = Lodash.get(
            rNew,
            ['data', 'consecutivo'],
            '',
          );

          const fechaPapeleta = Lodash.get(
            rNew,
            ['data', 'fecha'],
            '',
          );
          const nMontos = Lodash.get(
            rNew,
            ['data', 'montos'],
            '',
          );

          // COMPROBANTE: Se genera el Comprobante de la papeleta
          // Informacion General
          const generalInfo = {
            fecha: fechaPapeleta,
            consecutivo: nConsecutivo,
          };

          // Informacion Gestor
          const gestorInfo = {
            cedula: user.num_cedula,
            nombre: user.des_nombre,
          };

          // Informacion Comite
          const candidatosComite = [
            [
              1,
              tFrmsCandidatos.comite[0].cedula,
              tFrmsCandidatos.comite[0].nombre,
            ],
          ];
          // Informacion Candidatos
          const candidatosInfo = {
            comite: candidatosComite,
          };
          const montoInfo = {
            papeleta: nMontos.papeleta,
            membresia: nMontos.membresia,
            total: nMontos.total,
          };

          const sumInscripciones =
            parseFloat(
              balance.inscriptions.replace(/,/g, ''),
            ) +
            parseFloat(
              montoInfo.papeleta.replace(/,/g, ''),
            );
          const sumMembresias =
            parseFloat(
              balance.memberships.replace(/,/g, ''),
            ) +
            parseFloat(
              montoInfo.membresia.replace(/,/g, ''),
            );

          setGlobalBalance(
            Object.assign(balance, {
              inscriptions: `${formatData(
                sumInscripciones,
              )}.00`,
              memberships: `${formatData(
                sumMembresias,
              )}.00`,
            }),
          );

          generateComprobantePresidenteSector(
            'ASAMBLEAS DISTRITALES',
            generalInfo,
            candidatosInfo,
            gestorInfo,
            montoInfo,
          );

          // PROCESO DE PREVENCIÓN
          startPrevencion(
            user,
            nCodPapeleta,
            nConsecutivo,
            4,
            'Papeleta de Presidente Sector',
            fechaPapeleta,
            gestorInfo,
          );

          setSubmitting(false);
          setConsecutivo(nConsecutivo);
          setCodPapeleta(nCodPapeleta);
          setMsjDialog(
            'La información de la papeleta se GUARDÓ correctamente.',
          );
          setFinalDialog(true);
        });
      } else {
        updatePapeleta({
          codPapeleta,
          presidenteEnca,
        }).then(rUpdate => {
          const nCodPapeleta = Lodash.get(
            rUpdate,
            ['data', 'codPapeleta'],
            '',
          );

          const nMontos = Lodash.get(
            rUpdate,
            ['data', 'montos'],
            '',
          );
          if (nCodPapeleta !== '') {
            // COMPROBANTE: Se genera el Comprobante de la papeleta
            // Informacion General
            const generalInfo = {
              fecha: fecPapeleta,
              consecutivo,
            };

            // Informacion Gestor
            const gestorInfo = {
              cedula: user.num_cedula,
              nombre: user.des_nombre,
            };

            // Informacion Comite
            const candidatosComite = [
              [
                1,
                tFrmsCandidatos.comite[0].cedula,
                tFrmsCandidatos.comite[0].nombre,
              ],
            ];
            // Informacion Candidatos
            const candidatosInfo = {
              comite: candidatosComite,
            };
            const montoInfo = {
              papeleta: nMontos.papeleta,
              membresia: nMontos.membresia,
              total: nMontos.total,
            };

            generateComprobantePresidenteSector(
              'ASAMBLEAS DISTRITALES',
              generalInfo,
              candidatosInfo,
              gestorInfo,
              montoInfo,
            );

            setSubmitting(false);
            setMsjDialog(
              'La información de la papeleta se ACTUALIZÓ correctamente.',
            );
            setFinalDialog(true);
          } else {
            setMsjDialog(
              'La información NO pudo ser actualizada',
            );
            setOpenDialog(true);
          }
        });
      }
    } else {
      setMsjDialog(
        'Al menos debe de haber ingresado un candidato en la papeleta',
      );
      setOpenDialog(true);
      setIsSaving(false);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const savePapeleta = values => () => {
    setIsSaving(true);
    const tFrmsCandidatos = {
      comite: listPresiSector,
    };
    // Se valida que exista al menos 1 candidato en la papeleta
    const canSave = verifyIfCanSave(tFrmsCandidatos);
    if (canSave) {
      const presidenteEnca = {
        num_consecutivo: consecutivo,
        num_cedula: tFrmsCandidatos.comite[0].cedula,
        cedula_resp: user.num_cedula,
        usuario: user.des_login,
        cod_estado: 3,
      };

      if (consecutivo === '') {
        addNew({ presidenteEnca }).then(result => {
          setIsSaving(false);

          const nCodPapeleta = Lodash.get(
            result,
            ['data', 'codPapeleta'],
            '',
          );
          const nConsecutivo = Lodash.get(
            result,
            ['data', 'consecutivo'],
            '',
          );
          const fechaPapeleta = Lodash.get(
            result,
            ['data', 'fecha'],
            '',
          );
          setConsecutivo(nConsecutivo);
          setCodPapeleta(nCodPapeleta);
          setFecPapeleta(fechaPapeleta);
          setMsjDialog(
            'La información de la papeleta se GUARDÓ correctamente.',
          );
          setOpenDialog(true);
        });
      } else {
        updatePapeleta({
          codPapeleta,
          presidenteEnca,
        }).then(result => {
          const nCodPapeleta = Lodash.get(
            result,
            ['data', 'codPapeleta'],
            '',
          );
          if (nCodPapeleta !== '') {
            setMsjDialog(
              'La Información de la papeleta se ACTUALIZÓ correctamente.',
            );
            setOpenDialog(true);
          } else {
            setMsjDialog(
              'La información NO pudo ser actualizada',
            );
            setOpenDialog(true);
          }
        });
      }
    } else {
      setMsjDialog(
        'Al menos debe de haber ingresado un candidato en la papeleta',
      );
      setOpenDialog(true);
      setIsSaving(false);
    }
  };

  AddPresidenteSector.propTypes = {};

  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Nueva Papeleta de Presidente de Sector
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.btnBack}
                  onClick={() => {
                    history.push('/presi-sectores');
                  }}
                >
                  <ArrowBackIcon /> Regresar
                </Button>
              </Typography>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={saveForm}
              >
                {formik => (
                  <>
                    <form onSubmit={formik.handleSubmit}>
                      <Divider />

                      <div>
                        <Typography
                          component="h2"
                          variant="h6"
                          color="primary"
                          gutterBottom
                          className={classes.sectionTitle}
                        >
                          Datos del Gestor
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={4}>
                            <h4
                              className={classes.fieldTitle}
                            >
                              Estado
                            </h4>
                            <div
                              className={
                                classes.fieldFilled
                              }
                            >
                              {getEstadoPapeleta(
                                statusPapeleta,
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <h4
                              className={classes.fieldTitle}
                            >
                              Fecha
                            </h4>
                            <div
                              className={
                                classes.fieldFilled
                              }
                            >
                              {dateField}
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <h4
                              className={classes.fieldTitle}
                            >
                              Consecutivo
                            </h4>
                            <div
                              className={
                                consecutivo === ''
                                  ? classes.field
                                  : classes.fieldFilled
                              }
                            >
                              {consecutivo}
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <h4
                              className={classes.fieldTitle}
                            >
                              Cédula
                            </h4>
                            <div
                              className={
                                classes.fieldFilled
                              }
                            >
                              {user.num_cedula}
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <h4
                              className={classes.fieldTitle}
                            >
                              Nombre
                            </h4>
                            <div
                              className={
                                classes.fieldFilled
                              }
                            >
                              {user.des_nombre}
                            </div>
                          </Grid>
                        </Grid>

                        <ListSearch
                          title="PRESIDENTE(A) NACIONAL DE LA ORGANIZACIÓN SECTORIAL"
                          formik={formik}
                          initListData={listPresiSector}
                          sexOrderM={
                            Consts.presiSectorMOrder
                          }
                          sexOrderF={
                            Consts.presiSectorFOrder
                          }
                          fieldToShow={numCandidatos}
                          // eslint-disable-next-line radix
                          inPlace={0}
                          setListFunc={setlistPresiSector}
                          validateBy={[
                            {
                              dataVal: 'none',
                              value: 1,
                            },
                          ]}
                        />

                        <Grid
                          container
                          spacing={3}
                          style={{ marginTop: 40 }}
                        >
                          <Grid item xs={12}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              size="small"
                              className={
                                formik.isSubmitting ||
                                isSaving
                                  ? classes.btnSubmitting
                                  : classes.btnActions
                              }
                              disabled={
                                formik.isSubmitting ||
                                isSaving
                              }
                            >
                              {formik.isSubmitting ? (
                                <CircularProgress
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                  }}
                                />
                              ) : (
                                <>
                                  Enviar
                                  <SendIcon
                                    className={
                                      classes.frmIconButton
                                    }
                                  />
                                </>
                              )}
                            </Button>
                            <Button
                              type="button"
                              variant="contained"
                              color="secondary"
                              size="small"
                              className={
                                formik.isSubmitting ||
                                isSaving
                                  ? classes.btnSubmitting
                                  : classes.btnActions
                              }
                              disabled={
                                formik.isSubmitting ||
                                isSaving
                              }
                              onClick={savePapeleta(
                                formik.values,
                              )}
                            >
                              {isSaving ? (
                                <CircularProgress
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                  }}
                                />
                              ) : (
                                <>
                                  Guardar
                                  <SaveIcon
                                    className={
                                      classes.frmIconButton
                                    }
                                  />
                                </>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </form>
                  </>
                )}
              </Formik>
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle
                  id="alert-confirm-messages"
                  color="warning"
                >
                  Mensaje
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {msjDialog}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="primary"
                  >
                    Entendido
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={finalDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeFinalDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle
                  id="alert-finalDialog"
                  color="warning"
                >
                  Mensaje
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {msjDialog}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={closeFinalDialog}
                    color="primary"
                  >
                    Cerrar
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};

export default withStyles(style)(AddPresidenteSector);
