function createData(
  provincia,
  canton,
  cedula,
  primerApellido,
  segundoApellido,
  nombre,
  fecha,
  id,
) {
  return {
    provincia,
    canton,
    cedula,
    primerApellido,
    segundoApellido,
    nombre,
    fecha,
    id,
  };
}

export const tableHeaders = [
  {
    name: 'Provincia',
  },
  {
    name: 'Cantón',
  },
  {
    name: 'Cédula',
  },
  {
    name: '1er. Apellido',
  },
  {
    name: '2do. Apellido',
  },
  {
    name: 'Nombre',
  },
  {
    name: 'Fecha',
  },
];

export const dataRows = [
  createData(
    'HEREDIA',
    'BARVA',
    '401920238',
    'RAMIREZ',
    'RUIZ',
    'ESTEBAN',
    '26-03-2021',
    5334,
  ),
  createData(
    'HEREDIA',
    'SAN PABLO',
    '401940767',
    'ZUNIGA',
    'RUIZ',
    'JOSE',
    '26-03-2021',
    5335,
  ),
  createData(
    'HEREDIA',
    'SAN PABLO',
    '401900797',
    'MURILLO',
    'VARGAS',
    'ANDREA',
    '26-03-2021',
    5336,
  ),
  createData(
    'HEREDIA',
    'BARVA',
    '900150288',
    'RUIZ',
    'BARQUERO',
    'ISABEL',
    '26-03-2021',
    5337,
  ),
];
