import ConsultaElectores from '../Containers/ConsultaElectores/ConsultaElectores';
import RegistroGestores from '../Containers/RegistroGestores/RegistroGestores';
import Login from '../Containers/Login/Login';
import ForgotPassword from '../Containers/Login/ForgotPassword';
// import MainPage from '../Containers/MainPage/MainPage';
import Formulario from '../Containers/Formulario/Formulario';
import FormularioAuth from '../Containers/FormularioAuth/FormularioAuth';
import Dashboard from '../Containers/Dashboard/Dashboard';
import Distritales from '../Containers/Distritales/Distritales';
import AddDistritales from '../Containers/Distritales/AddDistritales';
import Cantonales from '../Containers/Cantonales/Cantonales';
import EditCantonales from '../Containers/Cantonales/EditCantonales';
import AddCantonales from '../Containers/Cantonales/AddCantonales';
import Provinciales from '../Containers/Provinciales/Provinciales';
import AddProvinciales from '../Containers/Provinciales/AddProvinciales';
import EditProvinciales from '../Containers/Provinciales/EditProvinciales';
import PresidencialSectorial from '../Containers/PresidencialSectorial/PresidencialSectorial';
import AddPresidenteSector from '../Containers/PresidencialSectorial/AddPresidenteSector';
import EditPresidenteSector from '../Containers/PresidencialSectorial/EditPresidenteSector';
import Sectoriales from '../Containers/Sectoriales/Sectoriales';
import AddPapeletaSector from '../Containers/Sectoriales/AddPapeletaSector';
import EditSectoriales from '../Containers/Sectoriales/EditSectoriales';
import Nacionales from '../Containers/Nacionales/Nacionales';
import AddNacionales from '../Containers/Nacionales/AddNacionales';
import EditNacionales from '../Containers/Nacionales/EditNacionales';
import EditDistritales from '../Containers/Distritales/EditDistritales';

import MiembrosMesa from '../Containers/MiembrosMesa/MiembrosMesa';
import AddMiembro from '../Containers/MiembrosMesa/AddMiembro';
import EditMiembro from '../Containers/MiembrosMesa/EditMiembro';

import FiscalesMesa from '../Containers/FiscalesMesa/FiscalesMesa';
import AddFiscales from '../Containers/FiscalesMesa/AddFiscales';
import EditFiscales from '../Containers/FiscalesMesa/EditFiscales';

import FiscalesGenerales from '../Containers/FiscalesGenerales/FiscalesGenerales';
import AddFiscalesGenerales from '../Containers/FiscalesGenerales/AddFiscalesGenerales';
import EditFiscalesGenerales from '../Containers/FiscalesGenerales/EditFiscalesGenerales';

import RegistroDepositos from '../Containers/RegistroDepositos/RegistroDepositos';
import AddDeposito from '../Containers/RegistroDepositos/AddDeposito';
import EditDeposito from '../Containers/RegistroDepositos/EditDeposito';

import ConsultaDebitos from '../Containers/ConsultaDebitos/ConsultaDebitos';

import Adhesiones from '../Containers/Adhesiones/Adhesiones';
import AddAdhesion from '../Containers/Adhesiones/AddAdhesion';
import Finanzas from '../Containers/Finanzas/Finanzas';

// Gafetes Electorales
import GafetesMiembrosMesa from '../Containers/GafetesElectorales/GafetesMiembrosMesa';
import GafetesFiscalesMesa from '../Containers/GafetesElectorales/GafetesFiscalesMesa';
import GafetesFiscalesGenerales from '../Containers/GafetesElectorales/GafetesFiscalesGenerales';

const routesList = [
  /* {
    auth: false,
    component: MainPage,
    exact: true,
    name: 'mainPage',
    path: '/',
  }, */
  {
    auth: false,
    component: Login,
    exact: true,
    name: 'mainPage',
    path: '/',
  },
  {
    auth: false,
    component: Login,
    exact: true,
    name: 'login',
    path: '/login',
  },
  {
    auth: false,
    component: ForgotPassword,
    exact: true,
    name: 'forgotPwd',
    path: '/forgot-password',
  },
  {
    auth: true,
    component: Adhesiones,
    exact: true,
    name: 'adhesiones',
    path: '/adhesiones/',
  },
  {
    auth: true,
    component: AddAdhesion,
    exact: true,
    name: 'addAdhesiones',
    path: '/adhesiones/add',
  },
  {
    auth: true,
    component: Finanzas,
    exact: true,
    name: 'finanzas',
    path: '/representantes-finanzas',
  },
  {
    auth: true,
    component: Dashboard,
    exact: true,
    name: 'dashboard',
    path: '/dashboard',
  },
  {
    auth: true,
    component: Distritales,
    exact: true,
    name: 'distritales',
    path: '/distritales',
  },
  {
    auth: true,
    component: AddDistritales,
    exact: true,
    name: 'addDistritales',
    path: '/distritales/add',
  },
  {
    auth: true,
    component: EditDistritales,
    exact: true,
    name: 'editDistritales',
    path: '/distritales/edit/:id',
  },
  {
    auth: true,
    component: Cantonales,
    exact: true,
    name: 'cantonales',
    path: '/cantonales',
  },
  {
    auth: true,
    component: AddCantonales,
    exact: true,
    name: 'addCantonales',
    path: '/cantonales/add',
  },
  {
    auth: true,
    component: EditCantonales,
    exact: true,
    name: 'editCantonales',
    path: '/cantonales/edit/:id',
  },
  {
    auth: true,
    component: Nacionales,
    exact: true,
    name: 'nacionales',
    path: '/nacionales',
  },
  {
    auth: true,
    component: AddNacionales,
    exact: true,
    name: 'addNacionales',
    path: '/nacionales/add',
  },
  {
    auth: true,
    component: EditNacionales,
    exact: true,
    name: 'editNacionales',
    path: '/nacionales/edit/:id',
  },
  {
    auth: true,
    component: Provinciales,
    exact: true,
    name: 'provinciales',
    path: '/provinciales',
  },
  {
    auth: true,
    component: AddProvinciales,
    exact: true,
    name: 'addProvinciales',
    path: '/provinciales/add',
  },
  {
    auth: true,
    component: EditProvinciales,
    exact: true,
    name: 'editProvinciales',
    path: '/provinciales/edit/:id',
  },
  {
    auth: true,
    component: PresidencialSectorial,
    exact: true,
    name: 'presiSectores',
    path: '/presi-sectores',
  },
  {
    auth: true,
    component: AddPresidenteSector,
    exact: true,
    name: 'addPresiSector',
    path: '/presi-sectores/add',
  },
  {
    auth: true,
    component: EditPresidenteSector,
    exact: true,
    name: 'editPresiSector',
    path: '/presi-sectores/edit/:id',
  },
  {
    auth: true,
    component: Sectoriales,
    exact: true,
    name: 'sectoriales',
    path: '/sectoriales',
  },
  {
    auth: true,
    component: AddPapeletaSector,
    exact: true,
    name: 'addPapeletaSector',
    path: '/sectoriales/add',
  },
  {
    auth: true,
    component: EditSectoriales,
    exact: true,
    name: 'editSectoriales',
    path: '/sectoriales/edit/:id',
  },
  {
    auth: true,
    component: MiembrosMesa,
    exact: true,
    name: 'miembros-mesa',
    path: '/miembros-mesa',
  },
  {
    auth: true,
    component: AddMiembro,
    exact: true,
    name: 'miembros-mesa-add',
    path: '/miembros-mesa/add',
  },
  {
    auth: true,
    component: EditMiembro,
    exact: true,
    name: 'miembros-mesa-edit',
    path: '/miembros-mesa/edit/:id',
  },
  {
    auth: true,
    component: FiscalesMesa,
    exact: true,
    name: 'fiscales-mesa',
    path: '/fiscales-mesa',
  },
  {
    auth: true,
    component: AddFiscales,
    exact: true,
    name: 'fiscales-mesa-add',
    path: '/fiscales-mesa/add',
  },
  {
    auth: true,
    component: EditFiscales,
    exact: true,
    name: 'fiscales-mesa-edit',
    path: '/fiscales-mesa/edit/:id',
  },
  {
    auth: true,
    component: FiscalesGenerales,
    exact: true,
    name: 'fiscales-generales',
    path: '/fiscales-generales',
  },
  {
    auth: true,
    component: AddFiscalesGenerales,
    exact: true,
    name: 'fiscales-generales-add',
    path: '/fiscales-generales/add',
  },
  {
    auth: true,
    component: EditFiscalesGenerales,
    exact: true,
    name: 'fiscales-generales-edit',
    path: '/fiscales-generales/edit/:id',
  },
  {
    auth: false,
    component: Formulario,
    exact: true,
    name: 'formulario',
    path: '/formulario/:typeForm',
  },
  {
    auth: true,
    component: FormularioAuth,
    exact: true,
    name: 'formularioAuth',
    path: '/formularioAuth/:typeForm',
  },
  {
    auth: false,
    component: ConsultaElectores,
    exact: true,
    name: 'consulta-electores',
    path: '/consulta-electores',
  },
  {
    auth: false,
    component: RegistroGestores,
    exact: true,
    name: 'registro-gestores',
    path: '/registro-gestores',
  },
  {
    auth: true,
    component: RegistroDepositos,
    exact: true,
    name: 'registroDepositos',
    path: '/registro-depositos',
  },
  {
    auth: true,
    component: AddDeposito,
    exact: true,
    name: 'addDeposito',
    path: '/registro-depositos/add',
  },
  {
    auth: true,
    component: EditDeposito,
    exact: true,
    name: 'editDeposito',
    path: '/registro-depositos/edit/:id',
  },
  {
    auth: true,
    component: ConsultaDebitos,
    exact: true,
    name: 'consultaDebitos',
    path: '/consulta-debitos',
  },
  {
    auth: true,
    component: GafetesMiembrosMesa,
    exact: true,
    name: 'gafetes-miembros-mesa',
    path: '/gafetes-miembros-mesa',
  },
  {
    auth: true,
    component: GafetesFiscalesMesa,
    exact: true,
    name: 'gafetes-fiscales-mesa',
    path: '/gafetes-fiscales-mesa',
  },
  {
    auth: true,
    component: GafetesFiscalesGenerales,
    exact: true,
    name: 'gafetes-fiscales-generales',
    path: '/gafetes-fiscales-generales',
  },
];

export default routesList;
