import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 40,
    paddingBottom: 40,
    maxWidth: 1380,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 35,
  },
  paper: {
    padding: 20,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  btnBack: {
    float: 'right',
    width: 150,
    height: 30,
    marginTop: 5,
  },
  btnSearch: {
    width: 150,
    height: 50,
  },
  btnActions: {
    float: 'right',
    height: 50,
    marginTop: 5,
    marginRight: 10,
  },
  btnActionCancel: {
    float: 'right',
    height: 50,
    marginTop: 5,
    marginRight: 10,
    '&:hover, &:focus': {
      backgroundColor: '#DA2229 !important',
      color: '#fff !important',
    },
  },
  sectionTitle: {
    fontSize: '16px',
    marginTop: 10,
    marginBottom: 10,
    padding: 5,
    color: '#fff',
    borderRadius: 4,
    background: '#008945',
  },
  field: {
    border: 'solid thin #ccc',
    padding: 20,
    background: '#f1f1f1',
  },
  fieldFilled: {
    border: 'solid thin #ccc',
    padding: 20,
    background: '#f1f1f1',
  },
  fieldMin: {
    border: 'solid thin #ccc',
    width: 35,
    padding: 20,
    textAlign: 'center',
    background: '#f1f1f1',
  },
  fieldMinFilled: {
    border: 'solid thin #ccc',
    width: 35,
    padding: 10,
    textAlign: 'center',
    background: '#f1f1f1',
  },
  fieldTitle: { margin: 5, padding: 0 },
  frmText: {
    width: '100%',
    height: '38px',
    padding: 5,
    margin: 0,
    display: 'block',
    fontSize: '.9em',
    lineHeight: 38,
    borderColor: '#797979',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 10,
  },
  frmIconButton: { marginLeft: 10 },
  btnSubmitting: {
    width: 138,
    float: 'right',
    height: 50,
    marginTop: 5,
    marginRight: 10,
  },
  formError: {
    margin: '5px 0px 25px 0px',
    color: `#FF1101`,
  },
}));

export default useStyles;
