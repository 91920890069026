/* eslint-disable radix */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Lodash from 'lodash';
import { useJwt } from 'react-jwt';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import style from './styles';

import { PlnDropdown } from '../../Components/Input';
import Copyright from '../Copyright/Copyright';
import ListSearch from '../../Components/ListSearch/ListSearch';
import { useMovimientos } from '../../Core/Api/locationApi';
import { getEstadoPapeleta } from '../../Core/Utils/Utils';
import { generateComprobanteNacional } from '../../Core/Utils/Comprobante';

import Consts from '../../Core/Consts/Consts';
import Storage from '../../Storage/Storage';

import {
  addNew,
  updatePapeleta,
  // getNumPapeletasNacionales,
  // getNumPapeletaStatus,
  // blockNumPapeleta,
} from '../../Core/Api/papeletaNacional';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const AddNacionales = props => {
  const { classes } = props;
  const history = useHistory();
  const listMovimientos = useMovimientos();

  const [user, setUser] = useState([]);
  const [statusPapeleta] = useState(3);
  const [dateField, setDateField] = useState('');
  const [consecutivo, setConsecutivo] = useState('');
  const [codPapeleta, setCodPapeleta] = useState(null);
  const [fecPapeleta, setFecPapeleta] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showfilters, setShowfilters] = useState(true);
  const [showNumPapeleta] = useState(false);
  const [sexFieldAs, setSexFieldAs] = useState('');
  const [
    isSexOrderRequired,
    setIsSexOrderRequired,
  ] = useState(true);
  const [onlyJuventud, setOnlyJuventud] = useState(false);

  const tokenData = Storage.instance;
  const { decodedToken } = useJwt(tokenData.getToken());

  const [msjDialog, setMsjDialog] = useState('');
  const [open, setOpenDialog] = React.useState(false);
  const [finalDialog, setFinalDialog] = React.useState(
    false,
  );
  const handleClose = () => {
    setOpenDialog(false);
    setIsSaving(false);
  };

  const closeFinalDialog = () => {
    history.push('/nacionales');
  };

  // Date Options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // timeZone: 'UTC',
  };

  useEffect(() => {
    if (decodedToken !== null) {
      setUser(decodedToken);
    }

    const today = new Date();
    setDateField(
      today.toLocaleDateString('es-ES', options),
    );
  }, [decodedToken, options]);

  const [showForm, setShowForm] = useState(false);

  // Opciones Seleccionadas
  const [sPuesto, setSPuesto] = useState(9);
  const [sMovimiento, setSMovimiento] = useState(9);
  const [numCandidatos, setNumCandidatos] = useState(1);
  const [
    listComiteNacional,
    setListComiteNacional,
  ] = useState(Consts.initListComiteNacional);

  // Lista de: Provincias, Cantones, Distritos
  const [initialValues] = useState({
    movimiento: 9,
    puesto: 9,
    nacional1: '',
    nacional2: '',
    nacional3: '',
    nacional4: '',
  });

  const movimientos = Lodash.get(
    listMovimientos,
    ['data', 'frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE EL MOVIMIENTO -',
        value: 9,
      },
    ],
  );

  const [puestos] = useState([
    { itemLabel: '- SELECCIONE EL PUESTO -', value: 9 },
    { itemLabel: 'Presidente', value: 1 },
    { itemLabel: 'Vicepresidente', value: 2 },
    { itemLabel: 'Secretario(a) General', value: 3 },
  ]);

  const selectMovimiento = m => {
    if (m !== 9) {
      initialValues.movimiento = m;

      if (m === 3 || m === 4) {
        setNumCandidatos(3);
      }

      if (m === 1 || m === 2) {
        setNumCandidatos(1);
      }

      if (m === 1) {
        setSexFieldAs('F');
        setIsSexOrderRequired(false);
      }
      if (m === 2) {
        setOnlyJuventud(true);
      }

      setSMovimiento(
        Lodash.find(movimientos, {
          value: m,
        }),
      );
    }
  };

  const setPuesto = p => {
    setShowForm(true);
    setShowfilters(false);
    initialValues.movimiento = sMovimiento.value;
    initialValues.puesto = p;

    if (p !== 9) {
      setSPuesto(
        Lodash.find(puestos, {
          value: p,
        }),
      );
    }
  };

  const validationSchema = Yup.object({});

  // Se valida que exista al menos 1 en la lista
  const verifyIfCanSave = lCandidates => {
    const totalRegistersOnList = Object.keys(
      lCandidates,
    ).map(c => {
      const total = Lodash.countBy(lCandidates[c], rec => {
        return rec.filled === true;
      });
      const result = Lodash.get(total, 'true', 0);
      return { count: result };
    });

    const canSave = Lodash.find(totalRegistersOnList, o => {
      return o.count > 0;
    });

    let status = true;
    if (!canSave) {
      status = false;
    }

    return status;
  };

  const saveForm = (values, { setSubmitting }) => {
    const { movimiento, puesto } = values;

    // Se valida que TODOS los campos esten completos al 100%
    const totalComite = Lodash.countBy(
      listComiteNacional,
      rec => {
        return rec.filled === false;
      },
    );

    if (totalComite.true !== undefined) {
      setMsjDialog(
        'Debe de completarse TODOS los campos de la papeleta al 100%',
      );
      setOpenDialog(true);
      setSubmitting(false);
      return;
    }

    const nacionalEnca = {
      ind_movimiento: movimiento,
      cod_estado: 2,
      num_puesto: puesto,
      num_consecutivo: consecutivo,
      cedula_resp: user.num_cedula,
      usuario: user.des_login,
    };

    const tFrmsCandidatos = {
      comite: listComiteNacional,
    };

    if (consecutivo === '') {
      // Se Crea Nueva Papeleta
      addNew({ nacionalEnca, tFrmsCandidatos }).then(
        rNew => {
          const nCodPapeleta = Lodash.get(
            rNew,
            ['data', 'codPapeleta'],
            '',
          );
          const nConsecutivo = Lodash.get(
            rNew,
            ['data', 'consecutivo'],
            '',
          );

          const fechaPapeleta = Lodash.get(
            rNew,
            ['data', 'fecha'],
            '',
          );
          const nMontos = Lodash.get(
            rNew,
            ['data', 'montos'],
            '',
          );

          // COMPROBANTE: Se genera el Comprobante de la papeleta
          // Informacion General
          const generalInfo = {
            movimiento: sMovimiento.itemLabel,
            puesto: sPuesto.itemLabel,
            fecha: fechaPapeleta,
            consecutivo: nConsecutivo,
          };

          // Informacion Gestor
          const gestorInfo = {
            cedula: user.num_cedula,
            nombre: user.des_nombre,
          };

          // Informacion Comite
          const candidatosComite = tFrmsCandidatos.comite.map(
            ({ cedula, lugar, nombre }) => {
              return [lugar, cedula, nombre];
            },
          );

          // Informacion Candidatos
          const candidatosInfo = {
            comite: candidatosComite,
          };
          const montoInfo = {
            papeleta: nMontos.papeleta,
            membresia: nMontos.membresia,
            total: nMontos.total,
          };

          generateComprobanteNacional(
            'ASAMBLEAS DISTRITALES',
            generalInfo,
            candidatosInfo,
            gestorInfo,
            montoInfo,
          );

          setSubmitting(false);
          setConsecutivo(nConsecutivo);
          setCodPapeleta(nCodPapeleta);
          setMsjDialog(
            'La información de la papeleta se GUARDÓ correctamente.',
          );
          setFinalDialog(true);
        },
      );
    } else {
      // Se Actualiza la Papeleta
      updatePapeleta({
        codPapeleta,
        nacionalEnca,
        tFrmsCandidatos,
      }).then(rUpdate => {
        const nCodPapeleta = Lodash.get(
          rUpdate,
          ['data', 'codPapeleta'],
          '',
        );

        const nMontos = Lodash.get(
          rUpdate,
          ['data', 'montos'],
          '',
        );

        if (nCodPapeleta !== '') {
          // COMPROBANTE: Se genera el Comprobante de la papeleta
          // Informacion General
          const generalInfo = {
            movimiento: sMovimiento.itemLabel,
            puesto: sPuesto.itemLabel,
            fecha: fecPapeleta,
            consecutivo,
          };

          // Informacion Gestor
          const gestorInfo = {
            cedula: user.num_cedula,
            nombre: user.des_nombre,
          };

          // Informacion Comite
          const candidatosComite = tFrmsCandidatos.comite.map(
            ({ cedula, lugar, nombre }) => {
              return [lugar, cedula, nombre];
            },
          );

          // Informacion Candidatos
          const candidatosInfo = {
            comite: candidatosComite,
          };
          const montoInfo = {
            papeleta: nMontos.papeleta,
            membresia: nMontos.membresia,
            total: nMontos.total,
          };

          generateComprobanteNacional(
            'ASAMBLEAS DISTRITALES',
            generalInfo,
            candidatosInfo,
            gestorInfo,
            montoInfo,
          );

          setSubmitting(false);
          setMsjDialog(
            'La Información de la papeleta se ACTUALIZÓ correctamente.',
          );
          setFinalDialog(true);
        } else {
          setMsjDialog(
            'La información NO pudo ser actualizada',
          );
          setSubmitting(false);
          setOpenDialog(true);
        }
      });
    }
  };

  const savePapeleta = values => () => {
    setIsSaving(true);
    const { movimiento, puesto } = values;

    const tFrmsCandidatos = {
      comite: listComiteNacional,
    };
    // Se valida que exista al menos 1 candidato en la papeleta
    const canSave = verifyIfCanSave(tFrmsCandidatos);
    if (canSave) {
      const nacionalEnca = {
        ind_movimiento: movimiento,
        cod_estado: 3,
        num_puesto: puesto,
        num_consecutivo: consecutivo,
        cedula_resp: user.num_cedula,
        usuario: user.des_login,
      };

      if (consecutivo === '') {
        addNew({ nacionalEnca, tFrmsCandidatos }).then(
          result => {
            const nCodPapeleta = Lodash.get(
              result,
              ['data', 'codPapeleta'],
              '',
            );
            const nConsecutivo = Lodash.get(
              result,
              ['data', 'consecutivo'],
              '',
            );
            const fechaPapeleta = Lodash.get(
              result,
              ['data', 'fecha'],
              '',
            );
            setConsecutivo(nConsecutivo);
            setCodPapeleta(nCodPapeleta);
            setFecPapeleta(fechaPapeleta);
            setMsjDialog(
              'La información de la papeleta se GUARDÓ correctamente.',
            );
            setOpenDialog(true);
          },
        );
      } else {
        updatePapeleta({
          codPapeleta,
          nacionalEnca,
          tFrmsCandidatos,
        }).then(result => {
          const nCodPapeleta = Lodash.get(
            result,
            ['data', 'codPapeleta'],
            '',
          );
          if (nCodPapeleta !== '') {
            setMsjDialog(
              'La Información de la papeleta se ACTUALIZÓ correctamente.',
            );
            setOpenDialog(true);
          } else {
            setMsjDialog(
              'La información NO pudo ser actualizada',
            );
            setOpenDialog(true);
          }
        });
      }
    } else {
      setMsjDialog(
        'Al menos debe de haber ingresado un candidato en la papeleta',
      );
      setOpenDialog(true);
      setIsSaving(false);
    }
  };

  AddNacionales.propTypes = {};

  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Nueva Papeleta Nacionales
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.btnBack}
                  onClick={() => {
                    history.push('/nacionales');
                  }}
                >
                  <ArrowBackIcon /> Regresar
                </Button>
              </Typography>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={saveForm}
              >
                {formik => (
                  <>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid
                        container
                        spacing={3}
                        style={
                          showfilters
                            ? { display: 'flex' }
                            : { display: 'none' }
                        }
                      >
                        <Grid item xs={12} sm={4}>
                          <h4>Movimiento</h4>
                          <Field
                            name="movimiento"
                            data={movimientos}
                            component={PlnDropdown}
                            onChange={mov =>
                              selectMovimiento(mov)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4>Puesto</h4>
                          <Field
                            name="puesto"
                            data={puestos}
                            component={PlnDropdown}
                            onChange={puesto =>
                              setPuesto(puesto)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        style={
                          showNumPapeleta
                            ? { display: 'flex' }
                            : { display: 'none' }
                        }
                      >
                        <Grid item xs={12} sm={3}>
                          <h4>Número de Papeleta:</h4>
                          <Field
                            name="papeleta"
                            data={[]}
                            component={PlnDropdown}
                            onChange={() => {}}
                          />
                        </Grid>
                      </Grid>
                      <Divider />

                      {showForm ? (
                        <div>
                          <Typography
                            component="h2"
                            variant="h6"
                            color="primary"
                            gutterBottom
                            className={classes.sectionTitle}
                          >
                            Datos del Gestor
                          </Typography>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Estado
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {getEstadoPapeleta(
                                  statusPapeleta,
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Fecha
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {dateField}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Consecutivo
                              </h4>
                              <div
                                className={
                                  consecutivo === ''
                                    ? classes.field
                                    : classes.fieldFilled
                                }
                              >
                                {consecutivo}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Cédula
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {user.num_cedula}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Nombre
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {user.des_nombre}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Movimiento
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {sMovimiento.itemLabel}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Puesto
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {sPuesto.itemLabel}
                              </div>
                            </Grid>
                          </Grid>

                          <ListSearch
                            title="Candidatos"
                            formik={formik}
                            initListData={
                              listComiteNacional
                            }
                            sexOrderM={
                              Consts.comiteNacionalMOrder
                            }
                            sexOrderF={
                              Consts.comiteNacionalFOrder
                            }
                            fieldToShow={numCandidatos}
                            // eslint-disable-next-line radix
                            inPlace={1}
                            setListFunc={
                              setListComiteNacional
                            }
                            validateBy={[
                              {
                                dataVal: 'none',
                                value: 1,
                              },
                            ]}
                            requireSexOrder={
                              isSexOrderRequired
                            }
                            sexFieldAs={sexFieldAs}
                            onlyJuventud={onlyJuventud}
                          />

                          <Grid
                            container
                            spacing={3}
                            style={{ marginTop: 40 }}
                          >
                            <Grid item xs={12}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                className={
                                  formik.isSubmitting ||
                                  isSaving
                                    ? classes.btnSubmitting
                                    : classes.btnActions
                                }
                                disabled={
                                  formik.isSubmitting ||
                                  isSaving
                                }
                              >
                                {formik.isSubmitting ? (
                                  <CircularProgress
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                    }}
                                  />
                                ) : (
                                  <>
                                    Enviar
                                    <SendIcon
                                      className={
                                        classes.frmIconButton
                                      }
                                    />
                                  </>
                                )}
                              </Button>
                              <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                size="small"
                                className={
                                  formik.isSubmitting ||
                                  isSaving
                                    ? classes.btnSubmitting
                                    : classes.btnActions
                                }
                                disabled={
                                  formik.isSubmitting ||
                                  isSaving
                                }
                                onClick={savePapeleta(
                                  formik.values,
                                )}
                              >
                                {isSaving ? (
                                  <CircularProgress
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                    }}
                                  />
                                ) : (
                                  <>
                                    Guardar
                                    <SaveIcon
                                      className={
                                        classes.frmIconButton
                                      }
                                    />
                                  </>
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        ''
                      )}
                    </form>
                  </>
                )}
              </Formik>
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle
                  id="alert-confirm-messages"
                  color="warning"
                >
                  Mensaje
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {msjDialog}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="primary"
                  >
                    Entendido
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={finalDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeFinalDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle
                  id="alert-finalDialog"
                  color="warning"
                >
                  Mensaje
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {msjDialog}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={closeFinalDialog}
                    color="primary"
                  >
                    Cerrar
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};

export default withStyles(style)(AddNacionales);
