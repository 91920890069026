import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const getAllAdhesiones = _params => {
  const { details, filters } = _params;
  const info = {
    details,
    filters,
  };

  return axiosInstance.post(`/adhesiones/getAll/`, info);
};

const getTotalAdhesiones = _tendencia => {
  return axiosInstance.get(
    `/adhesiones/totalAdhesiones/${_tendencia}`,
  );
};

const removeAdhesion = _infoRemove => {
  return axiosInstance.post(
    `/adhesiones/removeAdhesion/`,
    _infoRemove,
  );
};

const validateCedPadron = _cedula => {
  return axiosInstance.get(`
    /padron/validateCedula/${_cedula}`);
};

const validateAdhesionByCed = _cedula => {
  return axiosInstance.get(
    `/adhesiones/validateAdhesion/${_cedula}`,
  );
};

const saveAdhesion = _info => {
  return axiosInstance.post(
    '/adhesiones/saveAdhesion',
    _info,
  );
};

export default {
  getAdhesiones: getAllAdhesiones,
  validateOnPadron: validateCedPadron,
  saveNewAdhesion: saveAdhesion,
  validateAdhesion: validateAdhesionByCed,
  removeAdhesion,
  getTotalAdhesiones,
};
