import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Grid, Button, Divider } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Field } from 'formik';
import { PlnInput } from '../../Components/Input';
import PLNDialog from '../../Components/PLNDialog/PLNDialog';

import useStyles from './styles';
import {
  validateCedulaPadron,
  searchByCedula,
} from '../../Core/Api/padronApi';
import adhesionesApi from '../../Core/Api/adhesionesApi';
import Copyright from '../Copyright/Copyright';
import Storage from '../../Storage/Storage';

const AddAdhesion = () => {
  const classes = useStyles();
  const history = useHistory();

  const validationSchema = Yup.object({});

  const tokenData = Storage.instance;
  const { decodedToken } = useJwt(tokenData.getToken());

  const defaultData = {
    primerApellido: '',
    segundoApellido: '',
    nombre: '',
    celular: '',
    email: '',
    provincia: '',
    canton: '',
  };

  const [initialValues] = useState({
    cedula: '',
    celular: '',
    email: '',
  });

  const [searchBtnEnabled, setBtnSearch] = useState(false);
  const [searchBtnLabel, setBtnLabel] = useState('Buscar');

  const [showForm, setShowForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [open, setOpenDialog] = React.useState(false);

  // const [memberId, setMemberId] = useState('');
  const [userLogged, setUser] = useState([]);
  const [memberInfo, setMemberInfo] = useState(defaultData);
  const [isNewMemberValid, setIsValidMember] = useState(
    true,
  );
  const [validMemberMsj, setMemberMsj] = useState('');

  /**
   * Search member info using ced
   */
  const getDataByCed = async _form => {
    const { cedula } = _form.values;

    if (cedula) {
      setMemberInfo(defaultData);
      // setMemberId(cedula);
      setBtnSearch(true);
      setBtnLabel('Buscando...');
      setShowForm(false);

      const cedulaPadron = await validateCedulaPadron(
        cedula,
      );

      if (cedulaPadron.status === 200) {
        if (cedulaPadron.data.response === 0) {
          setIsValidMember(false);
          setMemberMsj(`Cédula no esta activa.`);
        } else {
          setIsValidMember(true);
          setMemberMsj(``);

          const validateAdhesion = await adhesionesApi.validateAdhesion(
            cedula,
          );

          if (validateAdhesion.status === 200) {
            if (validateAdhesion.data.response === 0) {
              setIsValidMember(true);
              setMemberMsj(``);

              const searcherInfo = await searchByCedula(
                cedula,
              );

              if (searcherInfo.status === 200) {
                const dataset = searcherInfo.data.response;

                if (dataset instanceof Array) {
                  setShowForm(true);
                  setMemberInfo({
                    primerApellido: dataset[0].des_apell1,
                    segundoApellido: dataset[0].des_apell2,
                    nombre: dataset[0].des_nombre,
                    celular: dataset[0].tel_celu || '',
                    email: dataset[0].des_email || '',
                    provincia: dataset[0].des_nombre_p,
                    canton: dataset[0].des_nombre_c,
                    codProvincia: dataset[0].cod_prov,
                    codCanton: dataset[0].cod_cant,
                  });

                  _form.setFieldValue(
                    'celular',
                    dataset[0].tel_celu || '',
                  );

                  _form.setFieldValue(
                    'email',
                    dataset[0].des_email || '',
                  );
                } else {
                  setShowForm(false);
                }
              } else {
                setIsValidMember(false);
                setMemberMsj(
                  `No se pudo obtener la informacion para el nuevo registro. Intente de nuevo.`,
                );
              }
            } else {
              setIsValidMember(false);
              setMemberMsj(
                `La cédula ya ha sido ingresada en otro registro.`,
              );
            }
          } else {
            setIsValidMember(false);
            setMemberMsj(
              `No se pudo realizar la consulta. Intente de nuevo`,
            );
          }
        }
      } else {
        setIsValidMember(false);
        setMemberMsj(
          `No se pudo realizar la consulta. Intente de nuevo`,
        );
      }

      setBtnSearch(false);
      setBtnLabel('Buscar');
    }
  };

  /**
   * Save Member
   * @param {*} values
   */
  const saveAdhesion = values => {
    setIsSaving(true);

    const phoneData = {
      tel_celu: values.celular,
    };

    const emailData = {
      des_email: values.email,
    };

    const adhesion = {
      num_cedula: parseInt(values.cedula, 10),
      cod_tendencia: userLogged.cod_tendencia,
      cod_usuario: userLogged.num_cedula,
    };

    const dataSet = {
      adhesion,
      telefonos: phoneData,
      emails: emailData,
    };

    try {
      adhesionesApi
        .saveNewAdhesion(dataSet)
        .then(response => {
          setIsSaving(false);

          if (response.status === 200) {
            if (response.data.response === 1) {
              setOpenDialog(true);
            } else {
              setIsSaving(true);
              console.warn(
                'No se pudo ingresar el nuevo registro. Intente de nuevo.',
              );
            }
          } else {
            console.warn(
              'No se pudo completar la operacion, vuelva a intentar.',
            );
          }
        });
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    if (decodedToken !== null) {
      setUser(decodedToken);
    }
  }, [decodedToken]);

  /**
   * Form validations
   * @param {*} values
   */
  const formikValidate = values => {
    const errors = {};
    const { celular, email } = values;

    if (celular && !/^[0-9]{8}$/.test(celular)) {
      errors.celular = 'Ejemplo de formato: xxxxxxxx.';
    }

    /* if (
      email ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        email,
      )
    ) {
      errors.email = 'Email inválido';
    } */

    if (email) {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          email,
        )
      )
        errors.email = 'Email inválido';
    }

    return errors;
  };

  const closeDialogHandler = () => {
    // history.push('/adhesiones');
    setTimeout(() => {
      window.location.href = '/adhesiones/add';
    }, 1500);
    setOpenDialog(false);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Nuevo Registro de Adhesión
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.btnBack}
                onClick={() => {
                  history.push('/adhesiones');
                }}
              >
                <ArrowBackIcon /> Regresar
              </Button>
            </Typography>
            <Formik
              validate={formikValidate}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={saveAdhesion}
            >
              {formik => (
                <form
                  onSubmit={formik.handleSubmit}
                  noValidate
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                      <h4>Cédula:</h4>
                      <Field
                        mask="999999999"
                        name="cedula"
                        component={PlnInput}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={classes.searchContainer}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={searchBtnEnabled}
                        className={classes.btnSearch}
                        onClick={async () => {
                          await getDataByCed(formik);
                        }}
                      >
                        {searchBtnLabel}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                      {!isNewMemberValid ? (
                        <div className={classes.formError}>
                          <Alert severity="error">
                            {validMemberMsj}
                          </Alert>
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: 10 }} />
                  {showForm ? (
                    <>
                      <Grid
                        container
                        spacing={3}
                        style={{ marginTop: 20 }}
                      >
                        <Grid item xs={12} sm={3}>
                          <h4
                            className={classes.fieldTitle}
                          >
                            1er. Apellido
                          </h4>
                          <div
                            className={classes.fieldFilled}
                          >
                            {memberInfo.primerApellido}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <h4
                            className={classes.fieldTitle}
                          >
                            2do. Apellido
                          </h4>
                          <div
                            className={classes.fieldFilled}
                          >
                            {memberInfo.segundoApellido}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <h4
                            className={classes.fieldTitle}
                          >
                            Nombre
                          </h4>
                          <div
                            className={classes.fieldFilled}
                          >
                            {memberInfo.nombre}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={5}>
                          <h4># Celular:</h4>
                          <Field
                            mask="99999999"
                            name="celular"
                            data={memberInfo.celular}
                            component={PlnInput}
                          />
                          {formik.errors.celular &&
                          formik.touched.celular ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.celular}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <h4>Correo Principal:</h4>
                          <Field
                            type="email"
                            name="email"
                            data={memberInfo.email}
                            component={PlnInput}
                          />
                          {formik.errors.email &&
                          formik.touched.email ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.email}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <h4
                            className={classes.fieldTitle}
                          >
                            Provincia
                          </h4>
                          <div
                            className={classes.fieldFilled}
                          >
                            {memberInfo.provincia}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4
                            className={classes.fieldTitle}
                          >
                            Cantón
                          </h4>
                          <div
                            className={classes.fieldFilled}
                          >
                            {memberInfo.canton}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        style={{ marginTop: 40 }}
                      >
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="small"
                            className={
                              formik.isSubmitting ||
                              isSaving
                                ? classes.btnSubmitting
                                : classes.btnActions
                            }
                            disabled={isSaving}
                          >
                            {isSaving ? (
                              <CircularProgress
                                style={{
                                  width: '30px',
                                  height: '30px',
                                }}
                              />
                            ) : (
                              <>
                                Guardar
                                <SaveIcon
                                  className={
                                    classes.frmIconButton
                                  }
                                />
                              </>
                            )}
                          </Button>
                          <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            size="small"
                            className={
                              classes.btnActionCancel
                            }
                            onClick={() => {
                              history.push('/adhesiones');
                            }}
                          >
                            Cancelar
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                </form>
              )}
            </Formik>
            <PLNDialog
              open={open}
              onClose={() => {}}
              title="Mensaje"
              bodymessage="Información guardada correctamente."
              buttons={[
                {
                  actionName: 'close',
                  color: 'simple',
                  title: 'Entendido',
                  clickHander: closeDialogHandler,
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default AddAdhesion;
