/* eslint-disable camelcase */
import axios from 'axios';
import Lodash from 'lodash';
import { useQuery } from 'react-query';
import Consts from '../Consts/Consts';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const addNew = params => {
  const { distritalEnca, tFrmsCandidatos } = params;

  const papeletaData = {
    distritalEnca,
    tFrmsCandidatos,
  };

  return axios.post('/papeletaDistrital', papeletaData);
};

export const getNumPapeletasDistritales = (
  idProvince,
  idCanton,
  idDistrito,
) => {
  const url = [
    `/papeletaDistrital/numPapeletas/${idProvince}/${idCanton}/${idDistrito}`,
  ];

  return axios.get(url.join('&'));
};

export const getNumPapeletaStatus = params => {
  const { papeleta, provincia, canton, distrito } = params;
  const url = [
    `/papeletaDistrital/numPapeletaStatus/${papeleta}/${provincia}/${canton}/${distrito}`,
  ];
  return axios.get(url.join('&'));
};

export const blockNumPapeleta = params => {
  const {
    numPapeleta,
    codProvincia,
    codCanton,
    codDistrito,
  } = params;

  const papeletaData = {
    numPapeleta,
    codProvincia,
    codCanton,
    codDistrito,
  };

  return axios.post(
    '/papeletaDistrital/blockNumPapeleta',
    papeletaData,
  );
};

export const updateFormFinanzas = params => {
  const {
    codTendencia,
    tel_tendencia,
    des_observacion,
    num_ced_rep1,
    num_ced_rep2,
    tel_rep1,
    tel_rep2,
    des_email_rep1,
    des_email_rep2,
    num_ced_con1,
    num_ced_con2,
    num_ced_con3,
    num_ced_con4,
    tel_con1,
    tel_con2,
    tel_con3,
    tel_con4,
    des_email_con1,
    des_email_con2,
    des_email_con3,
    des_email_con4,
    num_cole_con1,
    num_cole_con2,
    num_cole_con3,
    num_cole_con4,
  } = params;

  const frmData = {
    codTendencia,
    formData: {
      tel_tendencia,
      des_observacion,
      num_ced_rep1,
      num_ced_rep2,
      tel_rep1,
      tel_rep2,
      des_email_rep1,
      des_email_rep2,
      num_ced_con1,
      num_ced_con2,
      num_ced_con3,
      num_ced_con4,
      tel_con1,
      tel_con2,
      tel_con3,
      tel_con4,
      des_email_con1,
      des_email_con2,
      des_email_con3,
      des_email_con4,
      num_cole_con1,
      num_cole_con2,
      num_cole_con3,
      num_cole_con4,
    },
  };

  return axios.post('/formularioFinanzas/update', frmData);
};

const getAllDataFormulario = data => {
  const initValues = Consts.formularioFinanzasInitValues;
  Lodash.set(
    initValues,
    'telefono',
    data.tel_tendencia ? data.tel_tendencia : '',
  );
  Lodash.set(
    initValues,
    'nombre',
    data.NOM_CANDIDATO ? data.NOM_CANDIDATO : '',
  );
  Lodash.set(
    initValues,
    'nota',
    data.DES_OBSERVACION ? data.DES_OBSERVACION : '',
  );

  const iListRepFinanzas = Consts.initListRepFinanzas;
  const iListContadores = Consts.initListContadores;

  /* ====== Lista de Representantes Finanzas ====== */
  const listRepresentantes = iListRepFinanzas.map(
    (d, index) => {
      // Datos de la persona
      const personName = data[`nom_rep${index + 1}`]
        ? data[`nom_rep${index + 1}`]
        : '';
      const personCedula = data[`num_ced_rep${index + 1}`]
        ? data[`num_ced_rep${index + 1}`]
        : '';
      const personTelefono = data[`tel_rep${index + 1}`]
        ? data[`tel_rep${index + 1}`]
        : '';
      const personEmail = data[`des_email_rep${index + 1}`]
        ? data[`des_email_rep${index + 1}`]
        : '';
      const personEdad = 35;

      Lodash.set(
        initValues,
        `representante${index + 1}`,
        personCedula,
      );
      Lodash.set(
        initValues,
        `representante${index + 1}Tel`,
        personTelefono,
      );
      Lodash.set(
        initValues,
        `representante${index + 1}Email`,
        personEmail,
      );

      return {
        id: index + 1,
        lugar: index + 1,
        numPuesto: index + 1,
        fieldName: `representante${index + 1}`,
        fieldNameTel: `representante${index + 1}Tel`,
        fieldNameEmail: `representante${index + 1}Email`,
        fieldNameNumColegiado: `representante${
          index + 1
        }NumColegiado`,
        cedula: personCedula,
        nombre: personName,
        sexo: '',
        edad: personEdad,
        enabled: personName.length > 0,
        filled: personName.length > 0,
      };
    },
  );

  // Merging List Representantes Finanzas
  const allRepFinanzas = Lodash.merge(
    iListRepFinanzas,
    listRepresentantes,
  );

  /* ====== Lista de Contadores ====== */
  const listContadores = iListContadores.map((d, index) => {
    // Datos de la persona
    const personName = data[`nom_con${index + 1}`]
      ? data[`nom_con${index + 1}`]
      : '';
    const personCedula = data[`num_ced_con${index + 1}`]
      ? data[`num_ced_con${index + 1}`]
      : '';
    const personTelefono = data[`tel_con${index + 1}`]
      ? data[`tel_con${index + 1}`]
      : '';
    const personEmail = data[`des_email_con${index + 1}`]
      ? data[`des_email_con${index + 1}`]
      : '';
    const numColegiado = data[`num_cole_con${index + 1}`]
      ? data[`num_cole_con${index + 1}`]
      : '';
    const personEdad = 35;

    Lodash.set(
      initValues,
      `contador${index + 1}`,
      personCedula,
    );
    Lodash.set(
      initValues,
      `contador${index + 1}Tel`,
      personTelefono,
    );
    Lodash.set(
      initValues,
      `contador${index + 1}Email`,
      personEmail,
    );
    Lodash.set(
      initValues,
      `contador${index + 1}NumColegiado`,
      numColegiado,
    );

    return {
      id: index + 1,
      lugar: index + 1,
      numPuesto: index + 1,
      fieldName: `contador${index + 1}`,
      fieldNameTel: `contador${index + 1}Tel`,
      fieldNameEmail: `contador${index + 1}Email`,
      fieldNameNumColegiado: `contador${
        index + 1
      }NumColegiado`,
      cedula: personCedula,
      nombre: personName,
      sexo: '',
      edad: personEdad,
      enabled: personName.length > 0,
      filled: personName.length > 0,
    };
  });

  // Merging List Comite
  const allContadores = Lodash.merge(
    iListContadores,
    listContadores,
  );

  return {
    formularioData: data,
    candidatos: {
      listFinanzas: allRepFinanzas,
      listContadores: allContadores,
    },
    initValues,
  };
};

export const getFormularioInfo = async params => {
  try {
    const { codTendencia } = params;

    const url = [
      `/formularioFinanzas/getByCodTendencia/${codTendencia}`,
    ];

    const { data } = await axios.get(url.join('&'));

    const formularioData = getAllDataFormulario(
      data.response[0],
    );

    return formularioData;
  } catch (e) {
    return { formularioData: {} };
  }
};

export const useFormularioInfo = codTendencia => {
  return useQuery(['formularioData', codTendencia], () =>
    getFormularioInfo(codTendencia),
  );
};
