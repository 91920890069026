import axios from 'axios';
import Lodash from 'lodash';
import { useQuery } from 'react-query';
import Consts from '../Consts/Consts';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const addNew = params => {
  const { cantonalEnca, tFrmsCandidatos } = params;

  const papeletaData = {
    cantonalEnca,
    tFrmsCandidatos,
  };

  return axios.post('/papeletaCantonal', papeletaData);
};

export const getNumPapeletasCantonales = (
  idProvince,
  idCanton,
  idMovimiento,
) => {
  const url = [
    `/papeletaCantonal/numPapeletas/${idProvince}/${idCanton}/${idMovimiento}`,
  ];

  return axios.get(url.join('&'));
};

export const getNumPapeletaStatus = params => {
  const {
    papeleta,
    provincia,
    canton,
    movimiento,
  } = params;
  const url = [
    `/papeletaCantonal/numPapeletaStatus/${papeleta}/${provincia}/${canton}/${movimiento}`,
  ];
  return axios.get(url.join('&'));
};

export const blockNumPapeleta = params => {
  const {
    numPapeleta,
    codProvincia,
    codCanton,
    codMovimiento,
  } = params;

  const papeletaData = {
    numPapeleta,
    codProvincia,
    codCanton,
    codMovimiento,
  };

  return axios.post(
    '/papeletaCantonal/blockNumPapeleta',
    papeletaData,
  );
};

export const updatePapeleta = params => {
  const {
    codPapeleta,
    cantonalEnca,
    tFrmsCandidatos,
  } = params;

  const papeletaData = {
    codPapeleta,
    cantonalEnca,
    tFrmsCandidatos,
  };

  return axios.post(
    '/papeletaCantonal/update',
    papeletaData,
  );
};

const getCandidatosPapeleta = async codPapeleta => {
  const url = [
    `/papeletaCantonal/getCandidatosPapeleta/${codPapeleta}`,
  ];

  const { data } = await axios.get(url.join('&'));

  return data;
};

const searchByCedula = async cedula => {
  const url = [`/padron/searchbycedula/${cedula}`];
  const { data } = await axios.get(url.join('&'));
  return data;
};

const preparedList = list => {
  const unlockedList = list.map(e => {
    Lodash.set(e, 'enabled', false);
    return e;
  });
  return unlockedList;
};

const getAllDataPapeleta = async (data, codPapeleta) => {
  const initValues = Consts.cantonalesInitValues;
  Lodash.set(initValues, 'provincia', data.codprov);
  Lodash.set(initValues, 'canton', data.codcant);
  Lodash.set(initValues, 'movimiento', data.ind_movimiento);
  // Estado: REGISTRADA
  if (data.cod_estado === 2) {
    Lodash.set(initValues, 'papeleta', 999);
    // Estado: INSCRITA
  } else {
    const numStatus = await getNumPapeletaStatus({
      papeleta: data.numero,
      provincia: data.codprov,
      canton: data.codcant,
      movimiento: data.ind_movimiento,
    });

    const numeroStatus = Lodash.get(numStatus, ['data']);

    if (numeroStatus === 1) {
      Lodash.set(initValues, 'papeleta', data.numero);
    } else {
      Lodash.set(initValues, 'papeleta', 999);
    }
  }

  const allPapeletas = await getNumPapeletasCantonales(
    data.codprov,
    data.codcant,
    data.ind_movimiento,
  );

  const papeletasList = Lodash.get(allPapeletas, ['data']);
  const plist = papeletasList.map(({ numero }) => {
    return {
      itemLabel: numero,
      value: numero,
    };
  });
  plist.unshift({
    itemLabel: '- NUMERO DE PAPELETA -',
    value: 999,
  });

  // Se preparan las listas para que los campos esten desbloquedos
  const iListComite = preparedList(
    Consts.initListComiteCantonal,
  );

  // Se obtiene el numero de delegados
  const numCandidatos = 7;

  // Se obtienen todos los candidatos registrados en la papeleta
  const candidatos = await getCandidatosPapeleta(
    codPapeleta,
  );

  let sexFieldAs = '';
  let isSexOrderRequired = true;
  let onlyJuventud = false;

  if (data.ind_movimiento === 1) {
    sexFieldAs = 'F';
    isSexOrderRequired = false;
  }

  if (data.ind_movimiento === 2) {
    onlyJuventud = true;
  }

  /* ====== Lista de Comité ====== */
  const listComite = await Promise.all(
    candidatos.map(async (d, index) => {
      const miembro = await searchByCedula(d.NUM_CEDULA);

      // Datos de la persona
      const person = Lodash.get(miembro, ['response', 0]);

      // Nombre de la persona
      const personName = `${person.des_nombre} ${person.des_apell1} ${person.des_apell2}`;
      const personCedula = person.num_cedula;
      const personEdad = person.num_edad;
      let sexVal = 'M';
      if (person.sexo !== 1) {
        sexVal = 'F';
      }

      // Nombre del campo
      const field = Lodash.get(iListComite, [index]);

      Lodash.set(initValues, field.fieldName, personCedula);

      return {
        id: index + 1,
        lugar: field.lugar,
        numPuesto: d.NUM_PUESTO,
        fieldName: field.fieldName,
        cedula: personCedula,
        nombre: personName,
        sexo: sexVal,
        edad: personEdad,
        enabled: true,
        filled: true,
      };
    }),
  );

  // Merging List Comite
  const allComite = Lodash.merge(iListComite, listComite);
  const firstComite = allComite[0].sexo;
  let sexOrderComite = Consts.comiteCantonalMOrder;
  if (firstComite !== 'M') {
    sexOrderComite = Consts.comiteCantonalFOrder;
  }

  // Se establece el sexo para el resto de la lista
  const finalListComite = Lodash.map(
    allComite,
    (d, index) => {
      const newData = {
        id: d.id,
        lugar: d.lugar,
        numPuesto: d.numPuesto,
        fieldName: d.fieldName,
        cedula: d.cedula,
        nombre: d.nombre,
        sexo: isSexOrderRequired
          ? sexOrderComite[index].sexo
          : sexFieldAs,
        edad: d.edad,
        enabled: d.cedula !== '',
        filled: d.filled,
      };

      return newData;
    },
  );

  return {
    papeletaData: data,
    numCandidatos,
    papeletas: plist,
    candidatos: {
      listComite: finalListComite,
    },
    initValues,
    onlyJuventud,
    sexFieldAs,
    isSexOrderRequired,
  };
};

export const getPapeletaInfo = async params => {
  try {
    const { cedGestor, codPapeleta } = params;

    const url = [
      `/papeletaCantonal/getByCodPapeleta/${cedGestor}/${codPapeleta}`,
    ];

    const { data } = await axios.get(url.join('&'));
    const papeletaData = getAllDataPapeleta(
      data.response[0],
      codPapeleta,
    );

    return papeletaData;
  } catch (e) {
    return { papeletaData: {} };
  }
};

export const usePapeletaInfo = (cedGestor, codPapeleta) => {
  return useQuery(
    ['papeletaData', cedGestor, codPapeleta],
    () => getPapeletaInfo(cedGestor, codPapeleta),
  );
};
