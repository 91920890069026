import React from 'react';
import Lodash from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Copyright from '../Copyright/Copyright';
import Loading from '../../Components/Loading/Loading';
import { formatData } from '../../Core/Utils/Utils';

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: '#008945',
    margin: '20px 0',
    padding: 1,
  },
  paginatorRoot: {
    alignItems: 'flex-end',
    boxShadow: 'none',
    marginTop: '25px',
  },
  btnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  paginator: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    '& .Mui-selected': {
      backgroundColor: '#008945',
      '&:hover': {
        backgroundColor: '#84BF49',
      },
    },
  },
}));
const MacroList = props => {
  const {
    listName,
    btnConfig,
    btnSearch,
    tableHeads,
    tableBody,
    uiStylesClasses,
    actionConfig,
    filters,
    dataEmpty,
    showTotals,
    totalLabel,
    paginate,
  } = props;
  const classes = useStyles();

  const renderHeaders = () => {
    let template = [];
    let item = {};

    if (tableHeads.length > 0) {
      for (let x = 0; x < tableHeads.length; x++) {
        item = tableHeads[x];
        template.push(
          <TableCell key={`head-${x}`}>
            {item.name}
          </TableCell>,
        );
      }

      template.push(
        <TableCell key="data-options">&nbsp;</TableCell>,
      );

      template = (
        <TableHead key="row-headers">
          <TableRow>{template}</TableRow>
        </TableHead>
      );
    }

    return template;
  };

  const renderTableCells = _items => {
    const template = [];
    const itemProps = Object.getOwnPropertyNames(_items);

    if (itemProps.length) {
      for (let x = 0; x < itemProps.length; x++) {
        if (x + 1 !== itemProps.length) {
          template.push(
            <TableCell key={`row-child-id-${x}`}>
              {_items[itemProps[x]]}
            </TableCell>,
          );
        }
      }
    }

    return template;
  };

  const renderItemActions = _currentRow => {
    const cr = Lodash.get(_currentRow, ['cod_estado'], 0);

    let options = [];
    const actions = actionConfig();

    options = actions.map(option => {
      let showOpt = true;

      if (cr === 'Registrada' && option.key === 'edit') {
        showOpt = cr === 'Anulada';
      } else if (
        cr === 'Anulada' &&
        option.key === 'imprimir'
      ) {
        showOpt = false;
      } else if (
        cr === 'Inscrita' &&
        option.key === 'imprimir'
      ) {
        showOpt = false;
      } else if (
        cr === 'Anulada' &&
        option.key === 'remove'
      ) {
        showOpt = false;
      } else if (
        cr === 'Anulada' &&
        option.key === 'edit'
      ) {
        showOpt = false;
      } else if (
        cr === 'Prevención' &&
        option.key === 'edit'
      ) {
        showOpt = false;
      }

      return showOpt ? (
        <Tooltip
          title={option.aria}
          key={`tooltip-${option.key}`}
        >
          <IconButton
            key={`actions-block-${option.key}`}
            aria-label={option.aria}
            size="small"
            onClick={() => {
              if (option.clickHandler) {
                option.clickHandler(_currentRow);
              }
            }}
          >
            {option.icon}
          </IconButton>
        </Tooltip>
      ) : null;
    });

    return (
      <TableCell key={`actions-item-${_currentRow.id}`}>
        <div className={uiStylesClasses.actionContainer}>
          {options}
        </div>
      </TableCell>
    );
  };

  const renderPagination = () => {
    const {
      show,
      page,
      totalPages,
      onChangeHandler,
    } = paginate;

    let template = <></>;

    if (show) {
      template = (
        <Paper className={classes.paginatorRoot}>
          <Pagination
            className={classes.paginator}
            color="secondary"
            count={totalPages}
            page={page}
            onChange={onChangeHandler}
          />
        </Paper>
      );
    }

    return template;
  };

  const renderTableData = () => {
    let template = [];
    let row = {};

    if (tableBody.length > 0) {
      for (let x = 0; x < tableBody.length; x++) {
        row = tableBody[x];
        template.push(
          <TableRow key={`row-data-${x}`}>
            {renderTableCells(row)}
            {renderItemActions(row)}
          </TableRow>,
        );
      }

      template = (
        <TableBody key="body-data">{template}</TableBody>
      );
    }

    return template;
  };

  const renderTableTotals = () => {
    let template = [];
    let totalMonto = 0;
    for (let x = 0; x < tableBody.length; x++) {
      totalMonto += parseFloat(
        tableBody[x].montoDeposito.replace(/,/g, ''),
      );
    }
    if (tableBody.length > 0) {
      template = (
        <caption key={1}>
          Registros: {tableBody.length} | Total: &cent;
          {`${formatData(totalMonto)}.00`}
        </caption>
      );
    }

    return template;
  };

  const renderTotalDataSet = () => {
    return <caption key={1}>{totalLabel}</caption>;
  };

  const renderTable = () => {
    const errorMsj = 'No se encontraron registros';
    let template = <Loading label="Cargando" />;

    if (!dataEmpty) {
      if (tableHeads.length > 0 && tableBody.length > 0) {
        template = (
          <Table size="small">
            {showTotals
              ? [
                  renderHeaders(),
                  renderTableData(),
                  renderTotalDataSet(),
                ]
              : [renderHeaders(), renderTableData()]}
          </Table>
        );
      }
    } else {
      template = (
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          gutterBottom
        >
          {errorMsj}
        </Typography>
      );
    }

    return template;
  };

  React.useEffect(() => {
    // console.log(tableBody);
  });

  return (
    <>
      <Container
        maxWidth="lg"
        className={uiStylesClasses.container}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={uiStylesClasses.paper}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography
                    component="h2"
                    variant="h6"
                    color="primary"
                    gutterBottom
                  >
                    {listName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={uiStylesClasses.btnAdd}
                    onClick={() => btnConfig.event()}
                  >
                    {btnConfig.name}
                  </Button>
                </Grid>
              </Grid>
              {filters || <></>}

              {btnSearch ? (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={uiStylesClasses.btnAdd}
                    onClick={() => btnSearch.event()}
                  >
                    <SearchIcon /> {btnSearch.name}
                  </Button>
                </Grid>
              ) : null}

              <Divider className={classes.divider} />
              {renderTable()}
              {renderPagination()}
              <div className={uiStylesClasses.seeMore}>
                &nbsp;
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};

MacroList.defaultProps = {
  filters: null,
  showTotals: false,
  totalLabel: '',
  paginate: {},
  btnSearch: null,
};

MacroList.propTypes = {
  listName: PropTypes.string.isRequired,
  filters: PropTypes.shape({}),
  btnConfig: PropTypes.instanceOf(Object).isRequired,
  btnSearch: PropTypes.instanceOf(Object),
  actionConfig: PropTypes.instanceOf(Function).isRequired,
  tableHeads: PropTypes.instanceOf(Array).isRequired,
  tableBody: PropTypes.instanceOf(Array).isRequired,
  uiStylesClasses: PropTypes.instanceOf(Object).isRequired,
  dataEmpty: PropTypes.bool.isRequired,
  showTotals: PropTypes.bool,
  totalLabel: PropTypes.string,
  paginate: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
  }),
};

export default MacroList;
