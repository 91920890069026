/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Lodash from 'lodash';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { Formik } from 'formik';
import { useJwt } from 'react-jwt';
import * as Yup from 'yup';
import Loading from '../../Components/Loading/Loading';
import Copyright from '../Copyright/Copyright';
import ListSearch from '../../Components/ListSearch/ListSearch';
import Consts from '../../Core/Consts/Consts';
import style from './styles';
import { getEstadoPapeleta } from '../../Core/Utils/Utils';
import { generateComprobantePresidenteSector } from '../../Core/Utils/Comprobante';
import { startPrevencion } from '../../Core/Utils/Prevencion';
import Storage from '../../Storage/Storage';

import {
  updatePapeleta,
  usePapeletaInfo,
} from '../../Core/Api/papeletaPresidenteSector';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const EditPresidenteSector = props => {
  const { classes } = props;
  const { id } = useParams();
  const history = useHistory();

  const [user, setUser] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const [listComite, setListComite] = useState(
    Consts.initlistPresiSector,
  );

  const [msjDialog, setMsjDialog] = useState('');
  const [open, setOpenDialog] = React.useState(false);
  const [finalDialog, setFinalDialog] = React.useState(
    false,
  );
  const handleClose = () => {
    setOpenDialog(false);
    setIsSaving(false);
  };
  const closeFinalDialog = () => {
    history.push('/presi-sectores');
  };

  const validationSchema = Yup.object({});

  const tokenData = Storage.instance;
  const { decodedToken } = useJwt(tokenData.getToken());

  const numCedula = Lodash.get(
    decodedToken,
    ['num_cedula'],
    0,
  );

  const { data } = usePapeletaInfo({
    cedGestor: numCedula,
    codPapeleta: id,
  });

  // Datos Generales de la papeleta
  const papeletaData = Lodash.get(
    data,
    ['papeletaData'],
    [],
  );

  // Cantidad de Candidatos
  const numCandidatos = Lodash.get(
    data,
    ['numCandidatos'],
    [],
  );

  // Lista de Candidatos incritos
  const candidatos = Lodash.get(data, ['candidatos'], []);

  useEffect(() => {
    if (decodedToken) {
      setUser(decodedToken);
    }
  }, [decodedToken]);

  if (!Lodash.isEmpty(papeletaData)) {
    const {
      cod_estado,
      // num_puesto,
      // ind_movimiento,
      fec_registro,
      num_consecutivo,
    } = papeletaData;

    /*
    const isSexOrderRequired = Lodash.get(
      data,
      ['isSexOrderRequired'],
      [],
    );
    const sexFieldAs = Lodash.get(data, ['sexFieldAs'], []);
    const onlyJuventud = Lodash.get(
      data,
      ['onlyJuventud'],
      [],
    );
    */

    /*
    const filterMovimiento = Consts.movimientosCantonales.filter(
      status => {
        return status.codigo === ind_movimiento;
      },
    );

    const filterPuesto = Consts.puestosNacionales.filter(
      status => {
        return status.codigo === num_puesto;
      },
    );
    */

    // const desMovimiento = filterMovimiento[0].movimiento.toUpperCase();
    // const desPuesto = filterPuesto[0].descripcion.toUpperCase();

    // Date Options
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    const today = new Date(fec_registro);
    const papeletaDate = today.toLocaleDateString(
      'es-ES',
      options,
    );

    const initialValues = Lodash.get(
      data,
      ['initValues'],
      [],
    );

    // Se valida que exista al menos 1 en la lista
    const verifyIfCanSave = lCandidates => {
      const totalRegistersOnList = Object.keys(
        lCandidates,
      ).map(c => {
        const total = Lodash.countBy(
          lCandidates[c],
          rec => {
            return rec.filled === true;
          },
        );
        const result = Lodash.get(total, 'true', 0);
        return { count: result };
      });

      const canSave = Lodash.find(
        totalRegistersOnList,
        o => {
          return o.count > 0;
        },
      );

      let status = true;
      if (!canSave) {
        status = false;
      }

      return status;
    };

    const saveForm = (values, { setSubmitting }) => {
      // const { puesto, movimiento } = values;

      // Se valida que TODOS los campos esten completos al 100%
      const totalComite = Lodash.countBy(
        listComite,
        rec => {
          return rec.filled === false;
        },
      );

      if (totalComite.true !== undefined) {
        setMsjDialog(
          'Debe de completarse TODOS los campos de la papeleta al 100%',
        );
        setOpenDialog(true);
        setSubmitting(false);
        return;
      }

      // Detalle Papeleta
      const tFrmsCandidatos = {
        comite: listComite,
      };

      // Encabezado Papeleta
      const presidenteEnca = {
        cod_estado: 2,
        num_cedula: tFrmsCandidatos.comite[0].cedula,
        num_consecutivo,
        cedula_resp: user.num_cedula,
        usuario: user.des_login,
      };
      // num_cedula: tFrmsCandidatos.comite[0].cedula,

      // console.log(id);

      // Se Actualiza la Papeleta
      updatePapeleta({
        codPapeleta: id,
        presidenteEnca,
      }).then(rUpdate => {
        const nCodPapeleta = Lodash.get(
          rUpdate,
          ['data', 'codPapeleta'],
          '',
        );

        const nMontos = Lodash.get(
          rUpdate,
          ['data', 'montos'],
          '',
        );

        if (nCodPapeleta !== '') {
          // COMPROBANTE: Se genera el Comprobante de la papeleta
          // Informacion General
          const generalInfo = {
            fecha: papeletaDate,
            consecutivo: num_consecutivo,
          };

          // Informacion Gestor
          const gestorInfo = {
            cedula: user.num_cedula,
            nombre: user.des_nombre,
          };

          // Informacion Comite
          const candidatosComite = [
            [
              1,
              tFrmsCandidatos.comite[0].cedula,
              tFrmsCandidatos.comite[0].nombre,
            ],
          ];
          // Informacion Candidatos
          const candidatosInfo = {
            comite: candidatosComite,
          };
          const montoInfo = {
            papeleta: nMontos.papeleta,
            membresia: nMontos.membresia,
            total: nMontos.total,
          };

          generateComprobantePresidenteSector(
            'ASAMBLEAS DISTRITALES',
            generalInfo,
            candidatosInfo,
            gestorInfo,
            montoInfo,
          );

          // PROCESO DE PREVENCIÓN
          startPrevencion(
            user,
            nCodPapeleta,
            num_consecutivo,
            4,
            'Papeleta de Presidente Sector',
            papeletaDate,
            gestorInfo,
          );

          setSubmitting(false);
          setMsjDialog(
            'La información de la papeleta se ACTUALIZÓ correctamente.',
          );
          setFinalDialog(true);
        } else {
          setMsjDialog(
            'La información NO pudo ser actualizada',
          );
          setSubmitting(false);
          setOpenDialog(true);
        }
      });
    };

    // eslint-disable-next-line no-unused-vars
    const savePapeleta = values => () => {
      setIsSaving(true);
      // const { movimiento, puesto } = values;

      const tFrmsCandidatos = {
        comite: listComite,
      };

      const canSave = verifyIfCanSave(tFrmsCandidatos);

      if (canSave) {
        const presidenteEnca = {
          cod_estado: 3,
          num_cedula: tFrmsCandidatos.comite[0].cedula,
          num_consecutivo,
          cedula_resp: user.num_cedula,
          usuario: user.des_login,
        };

        updatePapeleta({
          codPapeleta: id,
          presidenteEnca,
        }).then(result => {
          const nCodPapeleta = Lodash.get(
            result,
            ['data', 'codPapeleta'],
            '',
          );
          if (nCodPapeleta !== '') {
            setMsjDialog(
              'La Información de la papeleta se ACTUALIZÓ correctamente.',
            );
            setOpenDialog(true);
          } else {
            setMsjDialog(
              'La información NO pudo ser actualizada',
            );
            setOpenDialog(true);
            setIsSaving(false);
          }
        });
      } else {
        setMsjDialog(
          'Al menos debe de haber ingresado un candidato en la papeleta',
        );
        setOpenDialog(true);
        setIsSaving(false);
      }
    };

    return (
      <>
        <Container
          maxWidth="lg"
          className={classes.container}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  {`Editar Papeleta de Presidente de Sector: ${id}`}
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.btnBack}
                    onClick={() => {
                      history.push('/presi-sectores');
                    }}
                  >
                    <ArrowBackIcon /> Regresar
                  </Button>
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={saveForm}
                >
                  {formik => (
                    <>
                      <form onSubmit={formik.handleSubmit}>
                        <Divider />

                        <div>
                          <Typography
                            component="h2"
                            variant="h6"
                            color="primary"
                            gutterBottom
                            className={classes.sectionTitle}
                          >
                            Datos del Gestor
                          </Typography>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Estado
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {getEstadoPapeleta(
                                  cod_estado,
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Fecha
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {papeletaDate}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Consecutivo
                              </h4>
                              <div
                                className={
                                  num_consecutivo === ''
                                    ? classes.field
                                    : classes.fieldFilled
                                }
                              >
                                {num_consecutivo}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Cédula
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {user.num_cedula}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Nombre
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {user.des_nombre}
                              </div>
                            </Grid>
                          </Grid>

                          <ListSearch
                            title="PRESIDENTE(A) NACIONAL DE LA ORGANIZACIÓN SECTORIAL"
                            formik={formik}
                            initListData={
                              candidatos.listComite
                            }
                            sexOrderM={
                              Consts.presiSectorMOrder
                            }
                            sexOrderF={
                              Consts.presiSectorFOrder
                            }
                            fieldToShow={numCandidatos}
                            // eslint-disable-next-line radix
                            inPlace={0}
                            setListFunc={setListComite}
                            validateBy={[
                              {
                                dataVal: 'none',
                                value: 1,
                              },
                            ]}
                          />

                          <Grid
                            container
                            spacing={3}
                            style={{ marginTop: 40 }}
                          >
                            <Grid item xs={12}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                className={
                                  formik.isSubmitting ||
                                  isSaving
                                    ? classes.btnSubmitting
                                    : classes.btnActions
                                }
                                disabled={
                                  formik.isSubmitting ||
                                  isSaving
                                }
                              >
                                {formik.isSubmitting ? (
                                  <CircularProgress
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                    }}
                                  />
                                ) : (
                                  <>
                                    Enviar
                                    <SendIcon
                                      className={
                                        classes.frmIconButton
                                      }
                                    />
                                  </>
                                )}
                              </Button>
                              <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                size="small"
                                className={
                                  formik.isSubmitting ||
                                  isSaving
                                    ? classes.btnSubmitting
                                    : classes.btnActions
                                }
                                disabled={
                                  formik.isSubmitting ||
                                  isSaving
                                }
                                onClick={savePapeleta(
                                  formik.values,
                                )}
                              >
                                {isSaving ? (
                                  <CircularProgress
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                    }}
                                  />
                                ) : (
                                  <>
                                    Actualizar
                                    <SaveIcon
                                      className={
                                        classes.frmIconButton
                                      }
                                    />
                                  </>
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </form>
                    </>
                  )}
                </Formik>
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle
                    id="alert-confirm-messages"
                    color="warning"
                  >
                    Mensaje
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      {msjDialog}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="primary"
                    >
                      Entendido
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={finalDialog}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={closeFinalDialog}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle
                    id="alert-finalDialog"
                    color="warning"
                  >
                    Mensaje
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      {msjDialog}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={closeFinalDialog}
                      color="primary"
                    >
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </>
    );
    // eslint-disable-next-line no-else-return
  } else {
    return (
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Loading label="Cargando" />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }
};

export default withStyles(style)(EditPresidenteSector);
