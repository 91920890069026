import axios from 'axios';
import Lodash from 'lodash';
import { useQuery } from 'react-query';
import Consts from '../Consts/Consts';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const addNew = params => {
  const { distritalEnca, tFrmsCandidatos } = params;

  const papeletaData = {
    distritalEnca,
    tFrmsCandidatos,
  };

  return axios.post('/papeletaDistrital', papeletaData);
};

export const getNumPapeletasDistritales = (
  idProvince,
  idCanton,
  idDistrito,
) => {
  const url = [
    `/papeletaDistrital/numPapeletas/${idProvince}/${idCanton}/${idDistrito}`,
  ];

  return axios.get(url.join('&'));
};

export const getNumPapeletaStatus = params => {
  const { papeleta, provincia, canton, distrito } = params;
  const url = [
    `/papeletaDistrital/numPapeletaStatus/${papeleta}/${provincia}/${canton}/${distrito}`,
  ];
  return axios.get(url.join('&'));
};

export const blockNumPapeleta = params => {
  const {
    numPapeleta,
    codProvincia,
    codCanton,
    codDistrito,
  } = params;

  const papeletaData = {
    numPapeleta,
    codProvincia,
    codCanton,
    codDistrito,
  };

  return axios.post(
    '/papeletaDistrital/blockNumPapeleta',
    papeletaData,
  );
};

export const updatePapeleta = params => {
  const {
    codPapeleta,
    distritalEnca,
    tFrmsCandidatos,
  } = params;

  const papeletaData = {
    codPapeleta,
    distritalEnca,
    tFrmsCandidatos,
  };

  return axios.post(
    '/papeletaDistrital/update',
    papeletaData,
  );
};

const getCandidatosPapeleta = async codPapeleta => {
  const url = [
    `/papeleta/getCandidatosPapeleta/${codPapeleta}`,
  ];

  const { data } = await axios.get(url.join('&'));

  return data;
};

const getCantidadDelegados = async (
  idProvince,
  idCanton,
  idDistrito,
) => {
  const url = [
    `/districts/delegados/${idProvince}/${idCanton}/${idDistrito}`,
  ];

  const { data } = await axios.get(url.join('&'));

  return data[0].numDelegados;
};

const searchByCedula = async cedula => {
  const url = [`/padron/searchbycedula/${cedula}`];
  const { data } = await axios.get(url.join('&'));
  return data;
};

const preparedList = list => {
  const unlockedList = list.map(e => {
    Lodash.set(e, 'enabled', false);
    return e;
  });
  return unlockedList;
};

const getAllDataPapeleta = async (data, codPapeleta) => {
  const initValues = Consts.distritalesInitValues;
  Lodash.set(initValues, 'provincia', data.codprov);
  Lodash.set(initValues, 'canton', data.codcant);
  Lodash.set(initValues, 'distrito', data.coddist);
  // Estado: REGISTRADA
  if (data.cod_estado === 2) {
    Lodash.set(initValues, 'papeleta', 999);
    // Estado: INSCRITA
  } else {
    const numStatus = await getNumPapeletaStatus({
      papeleta: data.numero,
      provincia: data.codprov,
      canton: data.codcant,
      distrito: data.coddist,
    });

    const numeroStatus = Lodash.get(numStatus, ['data']);

    if (numeroStatus === 1) {
      Lodash.set(initValues, 'papeleta', data.numero);
    } else {
      Lodash.set(initValues, 'papeleta', 999);
    }
  }

  const allPapeletas = await getNumPapeletasDistritales(
    data.codprov,
    data.codcant,
    data.coddist,
  );

  const papeletasList = Lodash.get(allPapeletas, ['data']);
  const plist = papeletasList.map(({ papeleta }) => {
    return {
      itemLabel: papeleta,
      value: papeleta,
    };
  });
  plist.unshift({
    itemLabel: '- NUMERO DE PAPELETA -',
    value: 999,
  });

  // Se preparan las listas para que los campos esten desbloquedos
  const iListDelegados = preparedList(
    Consts.initListDelegados,
  );

  const iListComite = preparedList(Consts.initListComite);
  const iListFiscales = preparedList(
    Consts.initListCandidatosFiscalia,
  );

  // Se obtiene el numero de delegados
  const numDelegados = await getCantidadDelegados(
    data.codprov,
    data.codcant,
    data.coddist,
  );

  // Se obtienen todos los candidatos registrados en la papeleta
  const candidatos = await getCandidatosPapeleta(
    codPapeleta,
  );

  /* ====== Lista de Delegados ====== */
  const delegados = candidatos.filter(candidato => {
    return candidato.NUM_PUESTO < 10;
  });

  const listDelegados = await Promise.all(
    delegados.map(async (d, index) => {
      const delegado = await searchByCedula(d.NUM_CEDULA);

      // Datos de la persona
      const person = Lodash.get(delegado, ['response', 0]);

      // Nombre de la persona
      const personName = `${person.des_nombre} ${person.des_apell1} ${person.des_apell2}`;
      const personCedula = person.num_cedula;
      const personEdad = person.num_edad;

      let sexVal = 'M';
      if (person.sexo !== 1) {
        sexVal = 'F';
      }

      Lodash.set(
        initValues,
        `delegado${index + 1}`,
        personCedula,
      );

      return {
        id: index + 1,
        lugar: index + 1,
        numPuesto: d.NUM_PUESTO,
        fieldName: `delegado${index + 1}`,
        cedula: personCedula,
        nombre: personName,
        sexo: sexVal,
        edad: personEdad,
        enabled: true,
        filled: true,
      };
    }),
  );

  // Merging List Delegados
  const allDelegados = Lodash.merge(
    iListDelegados,
    listDelegados,
  );

  const firstDelegado = allDelegados[0].sexo;
  let sexOrder = Consts.delegadosMOrder;
  if (firstDelegado !== 'M') {
    sexOrder = Consts.delegadosFOrder;
  }

  // Se establece el sexo para el resto de la lista
  const finalListDelegados = Lodash.map(
    allDelegados,
    (d, index) => {
      const newData = {
        id: d.id,
        lugar: d.lugar,
        numPuesto: d.numPuesto,
        fieldName: d.fieldName,
        cedula: d.cedula,
        nombre: d.nombre,
        sexo: sexOrder[index].sexo,
        edad: d.edad,
        enabled: d.cedula !== '',
        filled: d.filled,
      };

      return newData;
    },
  );

  /* ====== Lista de Comité ====== */
  const comite = candidatos.filter(candidato => {
    return (
      candidato.NUM_PUESTO >= 10 &&
      candidato.NUM_PUESTO < 16
    );
  });

  const listComite = await Promise.all(
    comite.map(async (d, index) => {
      const miembro = await searchByCedula(d.NUM_CEDULA);

      // Datos de la persona
      const person = Lodash.get(miembro, ['response', 0]);

      // Nombre de la persona
      const personName = `${person.des_nombre} ${person.des_apell1} ${person.des_apell2}`;
      const personCedula = person.num_cedula;
      const personEdad = person.num_edad;
      let sexVal = 'M';
      if (person.sexo !== 1) {
        sexVal = 'F';
      }

      // Nombre del campo
      const field = Lodash.get(iListComite, [index]);

      Lodash.set(initValues, field.fieldName, personCedula);

      return {
        id: index + 1,
        lugar: field.lugar,
        numPuesto: d.NUM_PUESTO,
        fieldName: field.fieldName,
        cedula: personCedula,
        nombre: personName,
        sexo: sexVal,
        edad: personEdad,
        enabled: true,
        filled: true,
      };
    }),
  );

  // Merging List Comite
  const allComite = Lodash.merge(iListComite, listComite);

  const firstComite = allComite[0].sexo;
  let sexOrderComite = Consts.comiteMOrder;
  if (firstComite !== 'M') {
    sexOrderComite = Consts.comiteFOrder;
  }

  // Se establece el sexo para el resto de la lista
  const finalListComite = Lodash.map(
    allComite,
    (d, index) => {
      const newData = {
        id: d.id,
        lugar: d.lugar,
        numPuesto: d.numPuesto,
        fieldName: d.fieldName,
        cedula: d.cedula,
        nombre: d.nombre,
        sexo: sexOrderComite[index].sexo,
        edad: d.edad,
        enabled: d.cedula !== '',
        filled: d.filled,
      };

      return newData;
    },
  );

  /* ====== Lista de Fiscales ====== */
  const fiscales = candidatos.filter(candidato => {
    return candidato.NUM_PUESTO > 15;
  });

  const listFiscales = await Promise.all(
    fiscales.map(async (d, index) => {
      const fiscal = await searchByCedula(d.NUM_CEDULA);

      // Datos de la persona
      const person = Lodash.get(fiscal, ['response', 0]);

      // Nombre de la persona
      const personName = `${person.des_nombre} ${person.des_apell1} ${person.des_apell2}`;
      const personCedula = person.num_cedula;
      const personEdad = person.num_edad;
      let sexVal = 'M';
      if (person.sexo !== 1) {
        sexVal = 'F';
      }

      // Nombre del campo
      const field = Lodash.get(iListFiscales, [index]);

      Lodash.set(initValues, field.fieldName, personCedula);

      return {
        id: index + 1,
        lugar: field.lugar,
        numPuesto: d.NUM_PUESTO,
        fieldName: field.fieldName,
        cedula: personCedula,
        nombre: personName,
        sexo: sexVal,
        edad: personEdad,
        enabled: d.NUM_CEDULA !== '',
        filled: true,
      };
    }),
  );

  // Merging List Comite
  const allFiscales = Lodash.merge(
    iListFiscales,
    listFiscales,
  );

  return {
    papeletaData: data,
    numDelegados,
    papeletas: plist,
    candidatos: {
      listDelegados: finalListDelegados,
      listComite: finalListComite,
      listFiscales: allFiscales,
    },
    initValues,
  };
};

export const getPapeletaInfo = async params => {
  try {
    const { cedGestor, codPapeleta } = params;

    const url = [
      `/papeletaDistrital/getByCodPapeleta/${cedGestor}/${codPapeleta}`,
    ];

    const { data } = await axios.get(url.join('&'));
    const papeletaData = getAllDataPapeleta(
      data.response[0],
      codPapeleta,
    );

    return papeletaData;
  } catch (e) {
    return { papeletaData: {} };
  }
};

export const usePapeletaInfo = (cedGestor, codPapeleta) => {
  return useQuery(
    ['papeletaData', cedGestor, codPapeleta],
    () => getPapeletaInfo(cedGestor, codPapeleta),
  );
};
