/* eslint-disable no-unused-vars */
import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import useStyles from '../Layout/makeStyles';
import Copyright from '../Copyright/Copyright';

export default function Dashboard() {
  const classes = useStyles();

  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        &nbsp;
      </Container>
    </>
  );
}
