/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import { useJwt } from 'react-jwt';
import Cleave from 'cleave.js/react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { Grid, MenuItem, Button } from '@material-ui/core';

import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import CircularProgress from '@material-ui/core/CircularProgress';

import { tableHeaders } from './data';
import MacroList from '../MacroList/MacroList';
import PLNDialog from '../../Components/PLNDialog/PLNDialog';

import Storage from '../../Storage/Storage';
import adhesionesApi from '../../Core/Api/adhesionesApi';

import {
  useProvincias,
  getCantonsByProvince,
} from '../../Core/Api/locationApi';

const useStyles = makeStyles(() => ({
  btnAdd: {
    float: 'right',
    width: 150,
    height: 30,
    marginTop: 5,
  },
  container: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  paper: {
    padding: 20,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdown: {
    borderRadius: 0,
    width: 290,
  },
  filterInput: {
    margin: '10px 0',
    paddingLeft: '10px !important',
    paddingTop: 0,
    '& .MuiInputBase-formControl': {
      height: 'inherit',
      border: 0,

      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
    },
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: '0 !important',
    paddingBottom: '0 !important',
  },
}));

const Adhesiones = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data } = useProvincias();
  const tokenStorage = Storage.instance;

  const { decodedToken } = useJwt(tokenStorage.getToken());

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [codProvincia, setCodProvincia] = useState(9);
  const [codCanton, setCodCanton] = useState(999);
  const [cantones, setCantones] = useState([
    { itemLabel: '- SELECCIONE EL CANTÓN -', value: 999 },
  ]);
  const [filteredItems, setFilteredItems] = useState([]); // used for filtering
  // eslint-disable-next-line no-unused-vars
  const [isBeingFiltered, setIsBeingFiltered] = useState(
    false,
  );
  const [isDataEmpty, setDataEmpty] = useState(true);
  const [filters, setFilters] = useState({
    provincia: 9,
    canton: 999,
    cedula: '',
    primerApellido: '',
    segundoApellido: '',
    nombre: '',
  });
  const [open, setOpenDialog] = React.useState(false);
  const [dataRemove, setDataRemove] = React.useState(null);
  const [isRemoved, setIsRemoved] = useState(false); // used on remove modal
  const [removedMsj, setRemoveMsj] = useState(
    'El registro se eliminará permanentemente. ¿Desea proceder?',
  );
  const [hiddenBtn, setHiddenBtn] = useState(false);

  const getFilterKeys = Object.getOwnPropertyNames(filters);
  const [totalItems, setTotalItems] = useState(0);
  const [
    totalItemsFiltered,
    setTotalItemsFiltered,
  ] = useState(0);
  const TIMING_ON_SEARCH = 900;
  const ITEMS_SHOW = 50;

  /**
   * Get Members when the page loads
   */
  const getAllAdhesiones = async _page => {
    let itemsList = [];

    // Date Options
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      // timeZone: 'UTC',
    };

    setDataEmpty(false);

    try {
      if (decodedToken) {
        const postedInfo = {
          details: {
            cod_tendencia: decodedToken.cod_tendencia,
            offset:
              _page === 1
                ? 0 * ITEMS_SHOW
                : _page * ITEMS_SHOW - ITEMS_SHOW,
            fetch: ITEMS_SHOW,
          },
          filters: {
            cod_provincia:
              codProvincia < 9 ? codProvincia : '',
            cod_canton: codCanton < 999 ? codCanton : '',
            num_cedula: filters.cedula || '',
            primerNombre: filters.nombre || '',
            primerApellido: filters.primerApellido || '',
            segundoApellido: filters.segundoApellido || '',
          },
        };

        const info = await adhesionesApi.getAdhesiones(
          postedInfo,
        );

        const dataRs = info.data.response.results;

        setTotalItemsFiltered(
          info.data.response.totalItems || 0,
        );

        if (
          dataRs instanceof Array &&
          info.status === 200
        ) {
          itemsList = dataRs.map(item => {
            return {
              provincia: item.des_nombre_p,
              canton: item.des_nombre_c,
              cedula: item.NUM_CEDULA,
              primerApellido: item.des_apell1,
              segundoApellido: item.des_apell2,
              nombre: item.des_nombre,
              fecha: item.FEC_REGISTRO,
              id: item.NUM_CEDULA,
            };
          });
        }
      }
    } catch (error) {
      console.warn(error);
    }

    setDataEmpty(itemsList.length === 0);
    setItems(itemsList);
    setFilteredItems(itemsList);
  };

  /**
   * Get count records
   */
  const getTotalAdhesiones = async () => {
    let total = 0;

    if (decodedToken) {
      const usrAdhesion = decodedToken.cod_tendencia;

      const requestTotal = await adhesionesApi.getTotalAdhesiones(
        usrAdhesion,
      );

      if (requestTotal.status === 200) {
        total = requestTotal.data.response;
      }
    }
    setTotalItems(total);
  };

  /**
   * Remove a member selected by user
   */
  const removeAdhesionByNum = async () => {
    const codAdhesion = dataRemove.id;

    if (codAdhesion && codAdhesion > 0) {
      setIsRemoved(true);

      const info = {
        adhesion: {
          num_cedula: codAdhesion,
          cod_tendencia: decodedToken.cod_tendencia,
        },
      };

      await adhesionesApi
        .removeAdhesion(info)
        .then(_response => {
          const resultInfo = _response.data.response;

          if (
            resultInfo === 1 &&
            _response.status === 200
          ) {
            setIsRemoved(false);
            setHiddenBtn(true);
            setRemoveMsj(
              'Adhesion ha sido eliminado correctamente.',
            );
            setTimeout(() => {
              setOpenDialog(false);
              window.location.href = '/adhesiones/';
            }, 3000);
          } else {
            setHiddenBtn(false);
            setRemoveMsj(
              'La operación no se pudo completar. Intente de nuevo.',
            );
          }
        });
    }
  };

  useEffect(() => {
    async function fetchListAPI() {
      await getAllAdhesiones(page);
      await getTotalAdhesiones();
    }

    fetchListAPI();
  }, [decodedToken]);

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    /* const actionEdit = {
      aria: 'Edit Adhesión',
      key: 'edit',
      showAction: true,
      icon: (
        <EditIcon
          fontSize="inherit"
          style={{ color: '#84BF48' }}
        />
      ),
      clickHandler: _item => {
        window.location.href = `/registro-adhesiones/edit/${_item.id}`;
      },
    };

    actions.push(actionEdit); */

    const actionRemove = {
      aria: 'Eliminar Adhesión',
      key: 'remove',
      showAction: true,
      icon: (
        <DeleteSharpIcon
          fontSize="inherit"
          style={{ color: '#cc0000' }}
        />
      ),
      clickHandler: _item => {
        setDataRemove(_item);
        setOpenDialog(true);
      },
    };

    actions.push(actionRemove);
    return actions;
  };

  const changeProvinciaHandler = async event => {
    const currentProv = event.target.value;

    setFilters({
      provincia: currentProv,
    });
    setCodProvincia(currentProv);
    setCodCanton(999);

    if (currentProv < 9) {
      await getCantonsByProvince(currentProv).then(rs => {
        const cantonesItms = [];
        if (rs.status === 200 && rs.data.response.length) {
          const itemsCant = rs.data.response;

          for (let x = 0; x < itemsCant.length; x++) {
            cantonesItms.push({
              itemLabel: itemsCant[x].des_nombre_c,
              value: itemsCant[x].cod_canton,
            });
          }
          cantonesItms.unshift({
            itemLabel: '- SELECCIONE EL CANTÓN -',
            value: 999,
          });
        }

        setCantones(cantonesItms);
      });
    }
  };

  const changeCantonHandler = event => {
    const currentCanton = event.target.value;

    setFilters({
      canton: currentCanton,
    });
    setCodCanton(currentCanton);
  };

  const ProvinciasDropdown = () => {
    const provincias = Lodash.get(
      data,
      ['frmOptions'],
      [
        {
          itemLabel: '- SELECCIONE LA PROVINCIA -',
          value: 9,
        },
      ],
    );

    const itemsProv = provincias.map(provincia => {
      return (
        <MenuItem
          value={provincia.value}
          key={`item-prov-${provincia.value}`}
        >
          {provincia.itemLabel}
        </MenuItem>
      );
    });

    return (
      <Select
        value={codProvincia}
        onChange={changeProvinciaHandler}
        className={classes.dropdown}
        variant="outlined"
        id="provincias-dropdown"
        style={{ width: '100%' }}
      >
        {itemsProv}
      </Select>
    );
  };

  const CantonesDropdown = () => {
    const itemsProv = cantones.map(canton => {
      return (
        <MenuItem
          value={canton.value}
          key={`item-prov-${canton.value}`}
        >
          {canton.itemLabel}
        </MenuItem>
      );
    });

    return (
      <Select
        value={codCanton}
        onChange={changeCantonHandler}
        className={classes.dropdown}
        variant="outlined"
        id="cantones-dropdown"
        style={{ width: '100%' }}
      >
        {itemsProv}
      </Select>
    );
  };

  /**
   * Action on page changes
   * @param {Number} currentPage
   */
  const paginate = async currentPage => {
    await setPage(currentPage);
    setDataEmpty(true);
    setItems([]);
    setFilteredItems([]);
    await getAllAdhesiones(currentPage);
  };

  const pageHandler = async (ev, value) => {
    ev.preventDefault();
    await paginate(value);
  };

  /**
   * Searching handler
   */
  const doSearching = () => {
    setPage(1);
    setDataEmpty(true);
    setItems([]);
    setFilteredItems([]);
    getAllAdhesiones(1);
  };

  return (
    <React.Fragment>
      <MacroList
        showTotals
        totalLabel={`Registros: ${totalItemsFiltered} de un total de ${totalItems}`}
        filters={
          <Grid item xs={12}>
            <Grid
              spacing={3}
              container
              direction="row"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid item xs={6} sm={4}>
                <ProvinciasDropdown />
              </Grid>
              <Grid item xs={6} sm={4}>
                <CantonesDropdown />
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                className={classes.grid}
                style={{
                  position: 'relative',
                }}
              >
                <Cleave
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  style={{
                    border: '1px #757575 solid',
                    borderRadius: 0,
                    padding: '15px 22px',
                    position: 'absolute',
                    top: 0,
                    marginTop: 12,
                    width: '94%',
                    fontSize: 18,
                    outline: 'none',
                  }}
                  onInit={filters.cedula}
                  id="cedula-txt"
                  placeholder="#cedula"
                  options={{
                    numericOnly: true,
                    blocks: [9],
                  }}
                  onChange={event => {
                    const updatedValue = {
                      cedula: event.currentTarget.value,
                    };

                    setFilters({
                      ...filters,
                      ...updatedValue,
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              spacing={3}
              container
              direction="row"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid
                item
                xs={6}
                sm={4}
                className={classes.grid}
              >
                <TextField
                  className={classes.filterInput}
                  id="primer-apellido-txt"
                  variant="outlined"
                  placeholder="1er Apellido"
                  value={filters.primerApellido}
                  onChange={async event => {
                    const updatedValue = {
                      primerApellido:
                        event.currentTarget.value,
                    };

                    await setFilters({
                      ...filters,
                      ...updatedValue,
                    });
                  }}
                  style={{ width: '100%', marginRight: 12 }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                className={classes.grid}
              >
                <TextField
                  className={classes.filterInput}
                  id="segundo-apellido-txt"
                  variant="outlined"
                  placeholder="2do Apellido"
                  value={filters.segundoApellido}
                  onChange={async event => {
                    const updatedValue = {
                      segundoApellido:
                        event.currentTarget.value,
                    };

                    await setFilters({
                      ...filters,
                      ...updatedValue,
                    });
                  }}
                  style={{ width: '100%', marginRight: 12 }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                className={classes.grid}
              >
                <TextField
                  className={classes.filterInput}
                  id="nombre-txt"
                  variant="outlined"
                  placeholder="Nombre"
                  value={filters.nombre}
                  onChange={async event => {
                    const updatedValue = {
                      nombre: event.currentTarget.value,
                    };

                    await setFilters({
                      ...filters,
                      ...updatedValue,
                    });
                  }}
                  style={{ width: '100%', marginRight: 12 }}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        dataEmpty={isDataEmpty}
        listName="Lista de Adhesiones"
        uiStylesClasses={classes}
        btnConfig={{
          name: '+ Nuevo',
          event: () => history.push('/adhesiones/add'),
        }}
        btnSearch={{
          name: 'Buscar',
          event: doSearching,
        }}
        actionConfig={setActions}
        tableHeads={tableHeaders}
        tableBody={isBeingFiltered ? filteredItems : items}
        paginate={{
          show:
            totalItemsFiltered > 0 &&
            totalItemsFiltered > ITEMS_SHOW,
          page,
          totalPages: Math.ceil(
            totalItemsFiltered / ITEMS_SHOW,
          ),
          onChangeHandler: pageHandler,
        }}
      />
      <PLNDialog
        open={open}
        onClose={() => {}}
        title="Eliminar Adhesión"
        bodymessage={removedMsj}
        buttons={
          hiddenBtn
            ? []
            : [
                {
                  actionName: 'cancel',
                  color: 'simple',
                  title: 'Cerrar',
                  clickHander: () => {
                    setOpenDialog(!open);
                  },
                },
                {
                  actionName: 'delete',
                  disabled: isRemoved,
                  color: 'remove',
                  title: isRemoved ? (
                    <CircularProgress
                      style={{
                        width: '28px',
                        height: '24px',
                      }}
                    />
                  ) : (
                    'Eliminar'
                  ),
                  clickHander: removeAdhesionByNum,
                },
              ]
        }
      />
    </React.Fragment>
  );
};

export default Adhesiones;
