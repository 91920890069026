import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Link } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PersonIcon from '@material-ui/icons/Person';
import style from './styles';

const Header = props => {
  const { classes } = props;

  return (
    <Container maxWidth={false}>
      <Grid container>
        <Grid item xs={6}>
          <img
            src="/logo2020.png"
            className={classes.imgLogo}
            alt="LogoPLN"
          />
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <ul
            className={classes.loginAccess}
            style={{ marginTop: 40 }}
          >
            <li>
              <PersonIcon />
              <Link href="/login">
                <span>Iniciar Sesión</span>
              </Link>
            </li>
          </ul>
          <ul
            className={classes.socialLinks}
            style={{ marginTop: 40 }}
          >
            <li>
              <Link
                href="https://www.facebook.com/partidoliberacionnacional"
                target="_blank"
              >
                <FacebookIcon />
              </Link>
            </li>
            <li>
              <Link
                href="https://twitter.com/plncr"
                target="_blank"
              >
                <TwitterIcon />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.youtube.com/user/plndigital?feature=watch"
                target="_blank"
              >
                <YouTubeIcon />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.instagram.com/partidoliberacionnacional/"
                target="_blank"
              >
                <InstagramIcon />
              </Link>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <AppBar position="static">&nbsp;</AppBar>
      </Grid>
    </Container>
  );
};

export default withStyles(style)(Header);
