import axios from 'axios';
import Lodash from 'lodash';
import { useQuery } from 'react-query';
import Consts from '../Consts/Consts';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const addNew = params => {
  const { sectorialEnca, tFrmsCandidatos } = params;

  const papeletaData = {
    sectorialEnca,
    tFrmsCandidatos,
  };

  return axios.post('/papeletaSectorial', papeletaData);
};

export const getNumPapeletasSectoriales = idSector => {
  const url = [
    `/papeletaSectorial/numPapeletas/${idSector}`,
  ];

  return axios.get(url.join('&'));
};

export const getNumPapeletaStatus = params => {
  const { papeleta, sector } = params;
  const url = [
    `/papeletaSectorial/numPapeletaStatus/${papeleta}/${sector}`,
  ];
  return axios.get(url.join('&'));
};

export const blockNumPapeleta = params => {
  const { numPapeleta, codSector } = params;

  const papeletaData = {
    numPapeleta,
    codSector,
  };

  return axios.post(
    '/papeletaSectorial/blockNumPapeleta',
    papeletaData,
  );
};

export const updatePapeleta = params => {
  const {
    codPapeleta,
    sectorialEnca,
    tFrmsCandidatos,
  } = params;

  const papeletaData = {
    codPapeleta,
    sectorialEnca,
    tFrmsCandidatos,
  };

  return axios.post(
    '/papeletaSectorial/update',
    papeletaData,
  );
};

const getCandidatosPapeleta = async codPapeleta => {
  const url = [
    `/papeletaSectorial/getCandidatosPapeleta/${codPapeleta}`,
  ];

  const { data } = await axios.get(url.join('&'));

  return data;
};

const searchByCedula = async cedula => {
  const url = [`/padron/searchbycedula/${cedula}`];
  const { data } = await axios.get(url.join('&'));
  return data;
};

const preparedList = list => {
  const unlockedList = list.map(e => {
    Lodash.set(e, 'enabled', false);
    return e;
  });
  return unlockedList;
};

const getAllDataPapeleta = async (data, codPapeleta) => {
  const initValues = Consts.sectorialesInitValues;
  Lodash.set(initValues, 'sector', data.ind_sector);

  // Estado: REGISTRADA
  if (data.cod_estado === 2) {
    Lodash.set(initValues, 'papeleta', 999);
    // Estado: INSCRITA
  } else {
    const numStatus = await getNumPapeletaStatus({
      papeleta: data.numero,
      sector: data.ind_sector,
    });

    const numeroStatus = Lodash.get(numStatus, ['data']);

    if (numeroStatus === 1) {
      Lodash.set(initValues, 'papeleta', data.numero);
    } else {
      Lodash.set(initValues, 'papeleta', 999);
    }
  }

  const allPapeletas = await getNumPapeletasSectoriales(
    data.ind_sector,
  );

  const papeletasList = Lodash.get(allPapeletas, ['data']);
  const plist = papeletasList.map(({ numero }) => {
    return {
      itemLabel: numero,
      value: numero,
    };
  });
  plist.unshift({
    itemLabel: '- NUMERO DE PAPELETA -',
    value: 999,
  });

  // Se preparan las listas para que los campos esten desbloquedos
  const iListComite = preparedList(
    Consts.initListComiteSectorial,
  );

  // Se obtiene el numero de delegados
  const numCandidatos = 3;

  // Se obtienen todos los candidatos registrados en la papeleta
  const candidatos = await getCandidatosPapeleta(
    codPapeleta,
  );

  const sexFieldAs = '';
  const isSexOrderRequired = true;
  const onlyJuventud = false;

  /* ====== Lista de Comité ====== */
  const listComite = await Promise.all(
    candidatos.map(async (d, index) => {
      const miembro = await searchByCedula(d.NUM_CEDULA);

      // Datos de la persona
      const person = Lodash.get(miembro, ['response', 0]);

      // Nombre de la persona
      const personName = `${person.des_nombre} ${person.des_apell1} ${person.des_apell2}`;
      const personCedula = person.num_cedula;
      const personEdad = person.num_edad;
      let sexVal = 'M';
      if (person.sexo !== 1) {
        sexVal = 'F';
      }

      // Nombre del campo
      const field = Lodash.get(iListComite, [index]);

      Lodash.set(initValues, field.fieldName, personCedula);

      return {
        id: index + 1,
        lugar: field.lugar,
        numPuesto: d.NUM_PUESTO,
        fieldName: field.fieldName,
        cedula: personCedula,
        nombre: personName,
        sexo: sexVal,
        edad: personEdad,
        enabled: true,
        filled: true,
      };
    }),
  );

  // Merging List Comite
  const allComite = Lodash.merge(iListComite, listComite);
  const firstComite = allComite[0].sexo;
  let sexOrderComite = Consts.comiteProvincialMOrder;
  if (firstComite !== 'M') {
    sexOrderComite = Consts.comiteProvincialFOrder;
  }

  // Se establece el sexo para el resto de la lista
  const finalListComite = Lodash.map(
    allComite,
    (d, index) => {
      const newData = {
        id: d.id,
        lugar: d.lugar,
        numPuesto: d.numPuesto,
        fieldName: d.fieldName,
        cedula: d.cedula,
        nombre: d.nombre,
        sexo: isSexOrderRequired
          ? sexOrderComite[index].sexo
          : sexFieldAs,
        edad: d.edad,
        enabled: d.cedula !== '',
        filled: d.filled,
      };

      return newData;
    },
  );

  /*
  return {
    papeletaData: [],
    numCandidatos: 4,
    papeletas: [],
    candidatos: {
      listComite: [],
    },
    initValues,
    onlyJuventud: false,
    sexFieldAs: '',
    isSexOrderRequired: true,
  };
  */

  return {
    papeletaData: data,
    numCandidatos,
    papeletas: plist,
    candidatos: {
      listComite: finalListComite,
    },
    initValues,
    onlyJuventud,
    sexFieldAs,
    isSexOrderRequired,
  };
};

export const getPapeletaInfo = async params => {
  try {
    const { cedGestor, codPapeleta } = params;

    const url = [
      `/papeletaSectorial/getByCodPapeleta/${cedGestor}/${codPapeleta}`,
    ];

    const { data } = await axios.get(url.join('&'));
    const papeletaData = getAllDataPapeleta(
      data.response[0],
      codPapeleta,
    );

    return papeletaData;
  } catch (e) {
    return { papeletaData: {} };
  }
};

export const usePapeletaInfo = (cedGestor, codPapeleta) => {
  return useQuery(
    ['papeletaData', cedGestor, codPapeleta],
    () => getPapeletaInfo(cedGestor, codPapeleta),
  );
};
