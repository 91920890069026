/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Grid, Button, Divider } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Field } from 'formik';
import {
  PlnInput,
  PlnDropdown,
} from '../../Components/Input';
import PLNDialog from '../../Components/PLNDialog/PLNDialog';

import useStyles from './styles';
import { searchByCedula } from '../../Core/Api/padronApi';
import { getDistritoByTwo } from '../../Core/Api/locationApi';
import fiscalesGeneralesApi from '../../Core/Api/fiscalesGeneralesApi';
import Consts from '../../Core/Consts/Consts';
import Copyright from '../Copyright/Copyright';
import Storage from '../../Storage/Storage';

const AddFiscalGeneral = () => {
  const classes = useStyles();
  const history = useHistory();

  const validationSchema = Yup.object({});

  const tokenData = Storage.instance;
  const { decodedToken } = useJwt(tokenData.getToken());

  const defaultData = {
    primerApellido: '',
    segundoApellido: '',
    nombre: '',
    celular: '',
    email: '',
    provincia: '',
    canton: '',
    distrito: '',
    jrv: '',
    role: '',
    codProvincia: 0,
    codCanton: 0,
  };

  const [distritos, setDistritos] = useState([
    { itemLabel: '- Seleccione un Distrito -', value: 999 },
  ]);

  const [jrv, setJRV] = useState([
    { itemLabel: '- Seleccione la JRV -', value: 999 },
  ]);

  const [roles, setRoles] = useState([
    { itemLabel: '- Seleccione un Puesto -', value: 999 },
  ]);

  const [initialValues] = useState({
    cedula: '',
    celular: '',
    email: '',
    distrito: 999,
    jrv: 999,
    role: 999,
  });

  const [searchBtnEnabled, setBtnSearch] = useState(false);
  const [searchBtnLabel, setBtnLabel] = useState('Buscar');

  const [showForm, setShowForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [open, setOpenDialog] = React.useState(false);

  // const [memberId, setMemberId] = useState('');
  const [userLogged, setUser] = useState([]);
  const [memberInfo, setMemberInfo] = useState(defaultData);
  const [isNewMemberValid, setIsValidMember] = useState(
    true,
  );
  const [validMemberMsj, setMemberMsj] = useState('');

  /**
   * Get Distrits using Prov and Cant
   * @param {*} _provId
   * @param {*} _cantId
   */
  const getDistritos = async (_provId, _cantId) => {
    if (_provId && _cantId) {
      await getDistritoByTwo(_provId, _cantId).then(
        _res => {
          const listDistr = _res.data.response;

          if (listDistr instanceof Array) {
            const dlist = listDistr.map(
              ({ cod_distrito, des_nombre_d }) => {
                return {
                  itemLabel: des_nombre_d,
                  value: cod_distrito,
                };
              },
            );
            dlist.unshift({
              itemLabel: '- Seleccione el Distrito -',
              value: 999,
            });

            setDistritos(dlist);
          }
        },
      );
    }
  };

  const getJRVSelected = _jrv => {
    const currentJRV = parseInt(_jrv, 10);

    if (currentJRV > 0 && currentJRV !== 999) {
      // setJRV(currentJRV);
      console.log(currentJRV);
    }
  };

  const getRoleSelected = _codRole => {
    const currentRole = parseInt(_codRole, 10);

    if (currentRole > 0 && currentRole !== 999) {
      // setRoles(currentJRV);
      console.log(currentRole);
    }
  };

  const getJRV = async _codDistr => {
    const currentDistr = parseInt(_codDistr, 10);

    if (currentDistr > 0 && currentDistr !== 999) {
      const { codProvincia, codCanton } = memberInfo;

      await fiscalesGeneralesApi
        .getJuntaReceptora(
          codProvincia,
          codCanton,
          currentDistr,
        )
        .then(_res => {
          const listJRV = _res.data.response;
          if (listJRV instanceof Array) {
            const jrvlist = listJRV.map(
              // eslint-disable-next-line no-unused-vars
              ({ num_jvirt, index }) => {
                return {
                  itemLabel: `Nº ${num_jvirt}`,
                  value: num_jvirt,
                };
              },
            );
            jrvlist.unshift({
              itemLabel: '- Seleccione la JRV -',
              value: 999,
            });

            setJRV(jrvlist);
          }
        });
    }
  };

  const buildRolesDropdown = () => {
    if (Consts.puestosFiscalGeneral.length > 0) {
      const rolesList = Consts.puestosFiscalGeneral.map(
        ({ codigo, descripcion }) => {
          return {
            itemLabel: descripcion,
            value: codigo,
          };
        },
      );

      rolesList.unshift({
        itemLabel: '- Seleccione un Puesto -',
        value: 999,
      });

      setRoles(rolesList);
    }
  };

  /**
   * Search member info using ced
   */
  const getDataByCed = async _form => {
    const { cedula } = _form.values;

    if (cedula) {
      setMemberInfo(defaultData);
      // setMemberId(cedula);
      setBtnSearch(true);
      setBtnLabel('Buscando...');
      setShowForm(false);

      const validateMemberResponse = await fiscalesGeneralesApi.validateFiscalGOnAdd(
        cedula,
      );

      if (
        validateMemberResponse.status === 200 &&
        validateMemberResponse.data.response === 1
      ) {
        setIsValidMember(false);
        setMemberMsj(
          // eslint-disable-next-line max-len
          `Esta persona ya está registrada como ${validateMemberResponse.data.tRegistered} en la JRV Nº ${validateMemberResponse.data.nMesa}, favor corregir`,
        );
      } else {
        setIsValidMember(true);
        setMemberMsj(``);
        await searchByCedula(cedula).then(_response => {
          const info = _response.data.response;

          if (info instanceof Array) {
            setShowForm(true);
            setMemberInfo({
              primerApellido: info[0].des_apell1,
              segundoApellido: info[0].des_apell2,
              nombre: info[0].des_nombre,
              celular: info[0].tel_celu || '',
              email: info[0].des_email || '',
              provincia: info[0].des_nombre_p,
              canton: info[0].des_nombre_c,
              distrito: info[0].des_nombre_d,
              jrv: info[0].num_jvirt,
              role: '',
              codProvincia: info[0].cod_prov,
              codCanton: info[0].cod_cant,
            });

            _form.setFieldValue(
              'celular',
              info[0].tel_celu || '',
            );

            _form.setFieldValue(
              'email',
              info[0].des_email || '',
            );

            getDistritos(
              info[0].cod_prov,
              info[0].cod_cant,
            );
          } else {
            setShowForm(false);
          }
        });
      }

      setBtnSearch(false);
      setBtnLabel('Buscar');
    }
  };

  /**
   * Save Member
   * @param {*} values
   */
  const saveMember = values => {
    setIsSaving(true);

    const memberData = {
      ind_tendencia: userLogged.cod_tendencia,
      cod_provincia: memberInfo.codProvincia,
      cod_canton: memberInfo.codCanton,
      cod_distrito: parseInt(values.distrito, 10),
      cod_mesa: parseInt(values.jrv, 10),
      num_cedula: parseInt(values.cedula, 10),
      cod_puesto: values.role,
      des_usuario: parseInt(userLogged.num_cedula, 10),
      fec_digitado: null,
      cod_periodo: null,
    };

    const userData = {
      codUsuario: userLogged.num_cedula,
    };

    const phoneData = {
      tel_celu: values.celular,
    };

    const emailData = {
      des_email: values.email,
    };

    const dataSet = {
      miembro: memberData,
      usuario: userData,
      telefonos: phoneData,
      emails: emailData,
      isUpdate: 0,
    };

    fiscalesGeneralesApi
      .submitFiscalGeneral(dataSet)
      .then(_response => {
        setIsSaving(false);

        if (
          _response.status === 200 &&
          _response.data.response === 1
        ) {
          setOpenDialog(true);
        } else {
          console.warn(
            'No se pudo completar la operacion, vuelva a intentar',
          );
        }
      });
  };

  useEffect(() => {
    if (decodedToken !== null) {
      setUser(decodedToken);
    }

    buildRolesDropdown();
  }, [decodedToken]);

  /**
   * Form validations
   * @param {*} values
   */
  const formikValidate = values => {
    const errors = {};
    // eslint-disable-next-line no-shadow
    const { celular, email, distrito, jrv, role } = values;

    if (!celular) {
      errors.celular = 'Requerido';
    } else if (!/^[0-9]{8}$/.test(celular)) {
      errors.celular = 'Ejemplo de formato: xxxxxxxx.';
    }

    if (!email) {
      errors.email = 'Requerido';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        email,
      )
    ) {
      errors.email = 'Email inválido';
    }

    if (distrito === 999) {
      errors.distrito = 'Seleccione un distrito';
    }

    if (jrv === 999) {
      errors.jrv = 'Seleccione la Junta Receptora';
    }

    if (role === 999) {
      errors.role = 'Seleccione un puesto';
    }

    return errors;
  };

  const closeDialogHandler = () => {
    history.push('/fiscales-generales');
    setOpenDialog(false);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Nuevo Registro de Fiscal General
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.btnBack}
                onClick={() => {
                  history.push('/fiscales-generales');
                }}
              >
                <ArrowBackIcon /> Regresar
              </Button>
            </Typography>
            <Formik
              validate={formikValidate}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={saveMember}
            >
              {formik => (
                <form
                  onSubmit={formik.handleSubmit}
                  noValidate
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                      <h4># Cédula:</h4>
                      <Field
                        mask="999999999"
                        name="cedula"
                        component={PlnInput}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={classes.searchContainer}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={searchBtnEnabled}
                        className={classes.btnSearch}
                        onClick={async () => {
                          await getDataByCed(formik);
                        }}
                      >
                        {searchBtnLabel}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                      {!isNewMemberValid ? (
                        <div className={classes.formError}>
                          <Alert severity="error">
                            {validMemberMsj}
                          </Alert>
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: 10 }} />
                  {showForm ? (
                    <>
                      <Grid
                        container
                        spacing={3}
                        style={{ marginTop: 20 }}
                      >
                        <Grid item xs={12} sm={3}>
                          <h4
                            className={classes.fieldTitle}
                          >
                            1er. Apellido
                          </h4>
                          <div
                            className={classes.fieldFilled}
                          >
                            {memberInfo.primerApellido}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <h4
                            className={classes.fieldTitle}
                          >
                            2do. Apellido
                          </h4>
                          <div
                            className={classes.fieldFilled}
                          >
                            {memberInfo.segundoApellido}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <h4
                            className={classes.fieldTitle}
                          >
                            Nombre
                          </h4>
                          <div
                            className={classes.fieldFilled}
                          >
                            {memberInfo.nombre}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={5}>
                          <h4># Celular:</h4>
                          <Field
                            mask="99999999"
                            name="celular"
                            data={memberInfo.celular}
                            component={PlnInput}
                          />
                          {formik.errors.celular &&
                          formik.touched.celular ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.celular}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <h4>Correo Principal:</h4>
                          <Field
                            type="email"
                            name="email"
                            data={memberInfo.email}
                            component={PlnInput}
                          />
                          {formik.errors.email &&
                          formik.touched.email ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.email}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <h4
                            className={classes.fieldTitle}
                          >
                            Provincia
                          </h4>
                          <div
                            className={classes.fieldFilled}
                          >
                            {memberInfo.provincia}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4
                            className={classes.fieldTitle}
                          >
                            Cantón
                          </h4>
                          <div
                            className={classes.fieldFilled}
                          >
                            {memberInfo.canton}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4
                            className={classes.fieldTitle}
                            style={{ marginTop: 0 }}
                          >
                            Distrito
                          </h4>
                          <div>
                            <Field
                              name="distrito"
                              data={distritos}
                              component={PlnDropdown}
                              onChange={dist => {
                                getJRV(dist);
                              }}
                            />
                            {formik.errors.distrito &&
                            formik.touched.distrito ? (
                              <div
                                className={
                                  classes.formError
                                }
                              >
                                <Alert severity="error">
                                  {formik.errors.distrito}
                                </Alert>
                              </div>
                            ) : null}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={5}>
                          <h4
                            className={classes.fieldTitle}
                            style={{ marginTop: 0 }}
                          >
                            JRV
                          </h4>
                          <Field
                            name="jrv"
                            data={jrv}
                            component={PlnDropdown}
                            onChange={jrvItem =>
                              getJRVSelected(jrvItem)
                            }
                          />
                          {formik.errors.jrv &&
                          formik.touched.jrv ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.jrv}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <h4
                            className={classes.fieldTitle}
                            style={{ marginTop: 0 }}
                          >
                            Puesto
                          </h4>
                          <Field
                            name="role"
                            data={roles}
                            component={PlnDropdown}
                            onChange={role =>
                              getRoleSelected(role)
                            }
                          />
                          {formik.errors.role &&
                          formik.touched.role ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.role}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        style={{ marginTop: 40 }}
                      >
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="small"
                            className={
                              formik.isSubmitting ||
                              isSaving
                                ? classes.btnSubmitting
                                : classes.btnActions
                            }
                            disabled={isSaving}
                          >
                            {isSaving ? (
                              <CircularProgress
                                style={{
                                  width: '30px',
                                  height: '30px',
                                }}
                              />
                            ) : (
                              <>
                                Guardar
                                <SaveIcon
                                  className={
                                    classes.frmIconButton
                                  }
                                />
                              </>
                            )}
                          </Button>
                          <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            size="small"
                            className={
                              classes.btnActionCancel
                            }
                            onClick={() => {
                              history.push(
                                '/fiscales-generales',
                              );
                            }}
                          >
                            Cancelar
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                </form>
              )}
            </Formik>
            <PLNDialog
              open={open}
              onClose={() => {}}
              title="Mensaje"
              bodymessage="Información guardada correctamente."
              buttons={[
                {
                  actionName: 'close',
                  color: 'simple',
                  title: 'Entendido',
                  clickHander: closeDialogHandler,
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default AddFiscalGeneral;
