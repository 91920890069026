/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Lodash from 'lodash';
import * as Yup from 'yup';
import { useJwt } from 'react-jwt';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Grid, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Formik, Field } from 'formik';
import Loading from '../../Components/Loading/Loading';
import { PlnInput } from '../../Components/Input';
import ListSearch from '../../Components/ListSearch/ListSearch';
import Consts from '../../Core/Consts/Consts';

import useStyles from './styles';
import Copyright from '../Copyright/Copyright';
import Storage from '../../Storage/Storage';
import regexUtils from '../../Core/Utils/RegExpUtils';

import {
  useFormularioInfo,
  updateFormFinanzas,
} from '../../Core/Api/formularioFinanzas';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const Finanzas = () => {
  const classes = useStyles();

  const [open, setOpenDialog] = useState(false);
  const [msjDialog, setMsjDialog] = useState('');
  const handleClose = () => {
    setOpenDialog(false);
  };

  // eslint-disable-next-line no-unused-vars
  const [listFinanzas, setListFinanzas] = useState(
    Consts.initListRepFinanzas,
  );

  // eslint-disable-next-line no-unused-vars
  const [listContadores, setListContadores] = useState(
    Consts.initListContadores,
  );

  const validationSchema = Yup.object({});

  const [user, setUser] = useState([]);
  const tokenData = Storage.instance;
  const { decodedToken } = useJwt(tokenData.getToken());
  const codTendencia = Lodash.get(
    decodedToken,
    ['cod_tendencia'],
    0,
  );

  const { data } = useFormularioInfo({
    codTendencia,
  });

  // Datos Generales de la papeleta
  const formularioData = Lodash.get(
    data,
    ['formularioData'],
    [],
  );

  useEffect(() => {
    if (decodedToken) {
      setUser(decodedToken);
    }
  }, [decodedToken]);

  const updateFinanzas = (values, { setSubmitting }) => {
    // eslint-disable-next-line no-console
    const {
      telefono,
      nota,
      representante1,
      representante2,
      representante1Tel,
      representante2Tel,
      representante1Email,
      representante2Email,
      contador1,
      contador2,
      contador3,
      contador4,
      contador1Tel,
      contador2Tel,
      contador3Tel,
      contador4Tel,
      contador1Email,
      contador2Email,
      contador3Email,
      contador4Email,
      contador1NumColegiado,
      contador2NumColegiado,
      contador3NumColegiado,
      contador4NumColegiado,
    } = values;

    updateFormFinanzas({
      codTendencia: user.cod_tendencia,
      tel_tendencia: telefono,
      des_observacion: nota,
      num_ced_rep1: representante1,
      num_ced_rep2: representante2,
      tel_rep1: representante1Tel,
      tel_rep2: representante2Tel,
      des_email_rep1: representante1Email,
      des_email_rep2: representante2Email,
      num_ced_con1: contador1,
      num_ced_con2: contador2,
      num_ced_con3: contador3,
      num_ced_con4: contador4,
      tel_con1: contador1Tel,
      tel_con2: contador2Tel,
      tel_con3: contador3Tel,
      tel_con4: contador4Tel,
      des_email_con1: contador1Email,
      des_email_con2: contador2Email,
      des_email_con3: contador3Email,
      des_email_con4: contador4Email,
      num_cole_con1: contador1NumColegiado,
      num_cole_con2: contador2NumColegiado,
      num_cole_con3: contador3NumColegiado,
      num_cole_con4: contador4NumColegiado,
    }).then(rUpdate => {
      const status = Lodash.get(
        rUpdate,
        ['data', 'response'],
        [],
      );
      if (status === 1) {
        setSubmitting(false);
        setMsjDialog(
          'La Información del Formulario de la Tendencia fue ACTUALIZADA.',
        );
        setOpenDialog(true);
      } else {
        setSubmitting(false);
        setMsjDialog(
          'La información del Formulario de la Tendencia NO pudo ser actualizada.',
        );
        setOpenDialog(true);
        setSubmitting(false);
      }
    });
  };

  // Lista de Candidatos incritos
  const candidatos = Lodash.get(data, ['candidatos'], []);

  useEffect(() => {
    if (decodedToken !== null) {
      setUser(decodedToken);
    }
  }, [decodedToken]);

  if (!Lodash.isEmpty(formularioData)) {
    const initialValues = Lodash.get(
      data,
      ['initValues'],
      [],
    );
    const { telefono, nombre, nota } = initialValues;

    /**
     * Form validations
     * @param {*} values
     */
    const formikValidate = values => {
      const errors = {};
      const {
        telefono,
        email,
        representante1,
        representante1Tel,
        representante1Email,
        representante2,
        representante2Tel,
        representante2Email,
        contador1,
        contador1Tel,
        contador1Email,
        contador1NumColegiado,
        contador2,
        contador2Tel,
        contador2Email,
        contador2NumColegiado,
        contador3,
        contador3Tel,
        contador3Email,
        contador3NumColegiado,
        contador4,
        contador4Tel,
        contador4NumColegiado,
        contador4Email,
      } = values;

      if (!telefono && !regexUtils.phone.test(telefono)) {
        errors.telefono = 'Formato debe tener 8 digitos.';
      }

      if (email) {
        if (!regexUtils.email.test(email))
          errors.email = 'Email inválido';
      }

      // REPRESENTANTE # 1
      if (representante1) {
        if (
          !representante1 ||
          !regexUtils.cedula.test(representante1)
        ) {
          errors.representante1 = 'Requerido';
        }

        if (
          !representante1Tel ||
          !regexUtils.phone.test(representante1Tel)
        ) {
          errors.representante1Tel = 'Tel. requerido';
        }

        if (
          !representante1Email ||
          !regexUtils.email.test(representante1Email)
        ) {
          errors.representante1Email = 'Email inválido';
        }
      }

      // REPRESENTANTE # 2
      if (representante2) {
        if (
          !representante2 ||
          !regexUtils.cedula.test(representante2)
        ) {
          errors.representante2 = 'Requerido';
        }

        if (
          !representante2Tel ||
          !regexUtils.phone.test(representante2Tel)
        ) {
          errors.representante2Tel = 'Tel. requerido';
        }

        if (
          !representante2Email ||
          !regexUtils.email.test(representante2Email)
        ) {
          errors.representante2Email = 'Email inválido';
        }
      }

      // CONTADOR # 1
      if (contador1) {
        if (
          !contador1 ||
          !regexUtils.cedula.test(contador1)
        ) {
          errors.contador1 = 'Requerido';
        }

        if (
          !contador1Tel ||
          !regexUtils.phone.test(contador1Tel)
        ) {
          errors.contador1Tel = 'Tel. requerido';
        }

        if (
          !contador1Email ||
          !regexUtils.email.test(contador1Email)
        ) {
          errors.contador1Email = 'Email inválido';
        }

        if (
          !contador1NumColegiado ||
          !regexUtils.digits.test(contador1NumColegiado)
        ) {
          errors.contador1NumColegiado = 'Requerido';
        }
      }

      // CONTADOR # 2
      if (contador2) {
        if (
          !contador2 ||
          !regexUtils.cedula.test(contador2)
        ) {
          errors.contador2 = 'Requerido';
        }

        if (
          !contador2Tel ||
          !regexUtils.phone.test(contador2Tel)
        ) {
          errors.contador2Tel = 'Tel. requerido';
        }

        if (
          !contador2Email ||
          !regexUtils.email.test(contador2Email)
        ) {
          errors.contador2Email = 'Email inválido';
        }

        if (
          !contador2NumColegiado ||
          !regexUtils.digits.test(contador2NumColegiado)
        ) {
          errors.contador2NumColegiado = 'Requerido';
        }
      }

      // CONTADOR # 3
      if (contador3) {
        if (
          !contador3 ||
          !regexUtils.cedula.test(contador3)
        ) {
          errors.contador3 = 'Requerido';
        }

        if (
          !contador3Tel ||
          !regexUtils.phone.test(contador3Tel)
        ) {
          errors.contador3Tel = 'Tel. requerido';
        }

        if (
          !contador3Email ||
          !regexUtils.email.test(contador3Email)
        ) {
          errors.contador3Email = 'Email inválido';
        }

        if (
          !contador3NumColegiado ||
          !regexUtils.digits.test(contador3NumColegiado)
        ) {
          errors.contador3NumColegiado = 'Requerido';
        }
      }

      // CONTADOR # 4
      if (contador4) {
        if (
          !contador4 ||
          !regexUtils.cedula.test(contador4)
        ) {
          errors.contador4 = 'Requerido';
        }

        if (
          !contador4Tel ||
          !regexUtils.phone.test(contador4Tel)
        ) {
          errors.contador4Tel = 'Tel. requerido';
        }

        if (
          !contador4Email ||
          !regexUtils.email.test(contador4Email)
        ) {
          errors.contador4Email = 'Email inválido';
        }

        if (
          !contador4NumColegiado ||
          !regexUtils.digits.test(contador4NumColegiado)
        ) {
          errors.contador4NumColegiado = 'Requerido';
        }
      }

      return errors;
    };

    return (
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Formulario de Acreditación de Representante
                de finanzas y contador(a)
              </Typography>
              <Formik
                validate={formikValidate}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={updateFinanzas}
              >
                {formik => (
                  <form
                    onSubmit={formik.handleSubmit}
                    noValidate
                  >
                    <>
                      <Grid
                        container
                        spacing={3}
                        style={{ marginTop: 20 }}
                      >
                        <Grid item xs={3}>
                          <h4
                            className={classes.fieldTitle}
                          >
                            Nombre del Precandidato(a):
                          </h4>
                        </Grid>
                        <Grid item xs={4}>
                          <div
                            className={classes.fieldFilled}
                          >
                            {nombre}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <h4>Teléfono de la tendencia:</h4>
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            mask="99999999"
                            name="telefono"
                            data={telefono}
                            component={PlnInput}
                          />
                          {formik.errors.telefono &&
                          formik.touched.telefono ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.telefono}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                      <ListSearch
                        title="Representante de Finanzas"
                        formik={formik}
                        initListData={
                          candidatos.listFinanzas
                        }
                        sexOrderM={
                          Consts.comiteNacionalMOrder
                        }
                        sexOrderF={
                          Consts.comiteNacionalFOrder
                        }
                        fieldToShow={2}
                        inPlace={1}
                        setListFunc={setListFinanzas}
                        validateBy={[
                          {
                            dataVal: 'none',
                            value: 1,
                          },
                        ]}
                        requireSexOrder={false}
                        hideSexColumn
                        hideColegiadoColumn
                        needHojaInfo={false}
                      />
                      <ListSearch
                        title="Contador(a) de la tendencia"
                        formik={formik}
                        initListData={
                          candidatos.listContadores
                        }
                        sexOrderM={
                          Consts.comiteNacionalMOrder
                        }
                        sexOrderF={
                          Consts.comiteNacionalFOrder
                        }
                        fieldToShow={4}
                        setListFunc={setListContadores}
                        validateBy={[
                          {
                            dataVal: 'none',
                            value: 1,
                          },
                        ]}
                        requireSexOrder={false}
                        hideSexColumn
                        needHojaInfo={false}
                      />
                      <Grid
                        container
                        spacing={0}
                        style={{ marginTop: 10 }}
                      >
                        <Grid item xs={12}>
                          <span>Nota:</span>
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="nota"
                            data={nota}
                            multiline
                            component={PlnInput}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        style={{ marginTop: 40 }}
                      >
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            className={
                              formik.isSubmitting
                                ? classes.btnSubmitting
                                : classes.btnActions
                            }
                            disabled={formik.isSubmitting}
                          >
                            {formik.isSubmitting ? (
                              <CircularProgress
                                style={{
                                  width: '30px',
                                  height: '30px',
                                }}
                              />
                            ) : (
                              <>
                                Actualizar
                                <SaveIcon
                                  className={
                                    classes.frmIconButton
                                  }
                                />
                              </>
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  </form>
                )}
              </Formik>
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle
                  id="alert-confirm-messages"
                  color="warning"
                >
                  Mensaje
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {msjDialog}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="primary"
                  >
                    Entendido
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    );
    // eslint-disable-next-line no-else-return
  } else {
    return (
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Loading label="Cargando" />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }
};

export default Finanzas;
