import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const getJRVByDistrit = (_ced, _cant, _codDistrital) => {
  return axiosInstance.get(
    `fiscalesMesa/getJuntas/${_ced}/${_cant}/${_codDistrital}`,
  );
};

const saveFiscalMesa = _info => {
  return axiosInstance.post('fiscalesMesa/', _info);
};

const getFiscalMesaByNumLinea = _linea => {
  return axiosInstance.get(`fiscalesMesa/find/${_linea}`);
};

const getFiscalesMesa = _ced => {
  return axiosInstance.get(
    `fiscalesMesa/allByGestor/${_ced}`,
  );
};

const getFiscalesMesaFiltered = (
  _ced,
  _codProvincia,
  _codCanton,
  _codDistrito,
  _nJrv,
) => {
  return axiosInstance.get(
    // eslint-disable-next-line max-len
    `fiscalesMesa/allByGestorFiltered/${_ced}/${_codProvincia}/${_codCanton}/${_codDistrito}/${_nJrv}`,
  );
};

const validateFiscalOnAdd = _ced => {
  return axiosInstance.get(
    `fiscalesMesa/validateCedula/${_ced}`,
  );
};

const validateIfExist = (jrv, puesto, cedula) => {
  return axiosInstance.get(
    `fiscalesMesa/validarExistencia/${jrv}/${puesto}/${cedula}`,
  );
};

const deleteFiscal = _numLinea => {
  return axiosInstance.get(
    `fiscalesMesa/removeMember/${_numLinea}`,
  );
};

export default {
  getJuntaReceptora: getJRVByDistrit,
  getAllFiscalesMesa: getFiscalesMesa,
  getAllFiscalesMesaFiltered: getFiscalesMesaFiltered,
  getFiscalInfoByLinea: getFiscalMesaByNumLinea,
  submitFiscalMesa: saveFiscalMesa,
  validateFiscalOnAdd,
  validateIfExist,
  deleteFiscal,
};
